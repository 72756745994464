/** React / Utils */
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';
import React, { useContext } from 'react';
import config from '../../../../utils/config';
/** Material UI */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

/** Local */
import DetailsTableRow from '../../../shared/table/detailsTableRow';
import ErrorBoundary from '../../../error/errorBoundary';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import ItemDetails from '../shared/itemDetails';
import ItemCharges from './itemCharges';
import OrderContext from '../../../../store/contexts/orderContext';
import translations from './charges.i18n';
import NewTabButton from '../../../shared/newTabButton';
import useHasPermission from '../../../../hooks/useHasPermission';
import { ViewPromoCodes } from '../../../../constants/permissions.const';
import { separateCancelledItems } from '../../../../utils/order';

/**
 * Charges tab which will contain the order and item line charges.
 */
function Charges() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const { hasPermission } = useHasPermission();

  const {
    ATHLETE,
    ITEM_CHARGES,
    ORDER_CHARGES,
    SHIPPING_COST,
    SHIPPING_DISCOUNT,
    SHIPPING_TAX,
    SHIPPING_TOTAL,
    PROMO_LIST_HEADER,
  } = mapValues(translations, i18nString);

  const { currency, headerCharges, headerTaxes, orderLines } = orderDetail;

  /** separate out cancelled and noncancelled item
   in order to display all the items in item charges tab
  */

  const [nonCancelledItems, cancelledItems] = separateCancelledItems(orderLines);

  /**
   * Return the specified value in a FormattedCurrency object  with the
   * current set according to what is in the order details.
   * @param {number} value monetary value to wrap.
   * @returns FormattedCurrency object.
   */

  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }

  /**
   * Find the amount in the header charges whose category matches the
   * specified category.
   * @param {string} category category of the header charge to retrieve the amount from.
   * @returns an object with the total for all matching charges and an array of each charge
   */
  function findHeaderCharges(category) {
    if (!Array.isArray(headerCharges)) return { total: 0, charges: [] };
    else {
      let chargesObject = { total: 0, charges: [] };
      // first, add to array all charges of the right category
      headerCharges.forEach((charge) => {
        if (charge.chargeCategory === category) {
          chargesObject.charges.push(charge);
        }
      });
      // then get total charge amount
      chargesObject.total = chargesObject.charges.reduce((acc, charge) => {
        return (acc += charge.chargeAmount);
      }, 0);
      // return object with total and all charges in case we want to use them later
      return chargesObject.charges.length ? chargesObject : { total: 0, charges: [] };
    }
  }

  /**
   * Finding all tax charges from an order details header taxes.
   * @returns an object with the total for tax charges and an array of each charge
   */
  function findingTaxInfo() {
    if (!Array.isArray(headerTaxes)) return { total: 0, charges: [] };
    else {
      let taxesObject = { total: 0, charges: [] };
      // first, add to array all tax charges
      headerTaxes.forEach((tax) => {
        if (tax.chargeCategory === 'SHIPPING') {
          taxesObject.charges.push({
            shippingTax: tax.tax,
            taxPercentage: tax.taxPercentage,
          });
        }
      });
      // then get total tax amount
      taxesObject.total = taxesObject.charges.reduce((acc, tax) => {
        return (acc += tax.shippingTax);
      }, 0);
      return taxesObject.charges.length ? taxesObject : { total: 0, charges: [] };
    }
  }

  function displayPromoCodes() {
    const canViewPromoCodeLink = hasPermission(ViewPromoCodes);
    return (
      <List className={classes.couponList} data-testid='list-coupon-codes'>
        {orderDetail?.couponCodes.map((code, i) => (
          <ListItem key={i} data-testid={`coupon-code-${i}`}>
            {canViewPromoCodeLink ? (
              <NewTabButton
                label={`${code}`}
                href={`${config.promoCodeUrl}/offer-details/${code}`}
              />
            ) : (
              <>{code}</>
            )}
          </ListItem>
        ))}
      </List>
    );
  }

  const shippingCharges = findHeaderCharges('SHIPPING');
  const shippingDiscounts = findHeaderCharges('DISCOUNT');
  const shippingTaxInfo = findingTaxInfo();

  const shippingTotal = shippingCharges?.total - shippingDiscounts?.total + shippingTaxInfo?.total;

  return (
    <ErrorBoundary>
      {/* List of Coupons / Promotions */}
      {Boolean(orderDetail?.couponCodes?.length) && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              title={<h2 className={classes.cardHeading}>{PROMO_LIST_HEADER}</h2>}
            />
            {displayPromoCodes()}
          </CardContent>
        </Card>
      )}

      {/* Order level charges */}
      <Card
        className={classes.cardSpacing}
        key='orderCharges'
        elevation={3}
        data-testid={'order-charges-content'}>
        <CardContent className={classes.cardContent}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            title={<h2 className={classes.cardHeading}>{ORDER_CHARGES}</h2>}
          />
          <Table>
            <TableBody>
              <DetailsTableRow
                header
                withLeftShift
                label={SHIPPING_TOTAL}
                value={formatCurrency(shippingTotal)}
                data-testid={'shipping-total'}
              />
              {/* map over all shipping charges to display amount */}
              {shippingCharges?.charges?.length ? (
                shippingCharges.charges.map((charge, i) => {
                  return (
                    <DetailsTableRow
                      key={i}
                      withLeftShift
                      label={SHIPPING_COST.concat(
                        shippingCharges?.charges?.length > 1 ? `_${i + 1}` : ''
                      )}
                      value={formatCurrency(charge.chargeAmount)}
                      data-testid={'shipping-cost'}
                    />
                  );
                })
              ) : (
                <DetailsTableRow
                  withLeftShift
                  label={SHIPPING_COST}
                  value={formatCurrency(0)}
                  data-testid={'shipping-cost'}
                />
              )}
              {/* Display shipping discount only if there is one. */}
              {Boolean(shippingDiscounts?.total) &&
                // map over all discounts to display amount and athlete
                shippingDiscounts?.charges?.map((discount, i) => {
                  return (
                    <>
                      <DetailsTableRow
                        key={`discount-${i}`}
                        withLeftShift
                        label={SHIPPING_DISCOUNT.concat(
                          shippingDiscounts?.charges?.length > 1 ? `_${i + 1}` : ''
                        )}
                        value={formatCurrency(discount?.chargeAmount)}
                        data-testid={'manual-discount'}
                      />
                      <DetailsTableRow
                        key={`athlete-${i}`}
                        row
                        indented
                        data-testid={'manual-discount-athlete'}
                        label={
                          <>
                            <span className={classes.shippingColorLabel}>{ATHLETE}: </span>
                            {discount?.agentReference || '--'}
                          </>
                        }
                      />
                    </>
                  );
                })}
              {/* map over all tax charges to display each amount and % */}
              {shippingTaxInfo?.charges?.length ? (
                shippingTaxInfo.charges.map((tax, i) => {
                  return (
                    <DetailsTableRow
                      key={i}
                      withLeftShift
                      label={SHIPPING_TAX.concat(
                        shippingTaxInfo?.charges?.length > 1 ? `_${i + 1}` : ''
                      )}
                      value={formatCurrency(tax.shippingTax)}
                      secondValue={tax.taxPercentage ? `(${tax.taxPercentage}%)` : null}
                      data-testid={'shipping-tax'}
                    />
                  );
                })
              ) : (
                <DetailsTableRow
                  withLeftShift
                  label={SHIPPING_TAX}
                  value={formatCurrency(0)}
                  data-testid={'shipping-tax'}
                />
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {/* Line item level charges */}
      <Card
        className={classes.cardSpacing}
        key='itemCharges'
        elevation={3}
        data-testid={'item-charges-content'}>
        <CardContent className={classes.cardContent}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            title={<h2 className={classes.cardHeading}>{ITEM_CHARGES}</h2>}
          />
          {Array.isArray(orderLines) && (
            <ItemDetails
              orderLines={[...nonCancelledItems, ...cancelledItems]}
              currency={currency}
              ExpandedContent={ItemCharges}
            />
          )}
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  cardHeaderRoot: {
    paddingLeft: '9px',
  },
  cardHeading: {
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  cardContent: {
    overflow: 'auto',
  },
  productInformationTableRow: {
    height: '100%',
  },
  shortTableCell: {
    border: 'none',
    borderBottom: 'none',
    height: '10px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  shortTableHeader: {
    border: 'none',
    borderBottom: 'none',
    color: theme.palette.grey[700],
    fontSize: '0.8125rem',
    height: '10px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'pre',
  },
  couponList: {
    paddingLeft: theme.spacing(2),
  },
  shippingColorLabel: {
    color: theme.palette.grey[900],
  },
}));

export default Charges;
