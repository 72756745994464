/** React/Utils */
import React, { useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import decodeToken from 'jwt-decode';

/** Material-UI */
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import { DialogTypes } from '../../../constants/dialog.const';
import Geo from '../../../constants/geos.const';
import {
  AddComment,
  CancelOrders,
  CreateReturn,
  SendResendReturnLabel,
  InspectReturn,
  ModifyPrice,
  ReinspectReturn,
  DiscountShipping,
  ExchangeProduct,
} from './../../../constants/permissions.const';
import useHasPermission from './../../../hooks/useHasPermission';
import { actions as dialogActions } from '../../../store/actions/dialogActions';
import { DialogContext } from '../../../store/contexts/dialogContext';
import { OrderContext } from '../../../store/contexts/orderContext';
import ErrorBoundary from '../../error/errorBoundary';
import { NoPermission } from '../../shared/noPermission/noPermission';
import CommentStep1 from './addComment/commentStep1';
import CancelStep1 from './cancelItems/step1';
import CancelStep2 from './cancelItems/step2';
import CancelStepControl from './cancelItems/stepControl';
import ExchangeStepControl from './exchange/stepControl';
import ExchangeStep1 from './exchange/step1';
import ExchangeStep2 from './exchange/step2';
import ExchangeStep3 from './exchange/step3';
import ExchangeStep4 from './exchange/step4';
import ReturnStep1 from './createReturn/step1';
import ReturnStep2 from './createReturn/step2';
import ReturnStep3 from './createReturn/step3';
import ChinaReturnStep3 from './createReturn/step3.CN';
import KoreaReturnStep3 from './createReturn/step3.KR';
import ReturnStep4 from './createReturn/step4';
import USReturnStepControl from './createReturn/stepControl.US';
import EUReturnStepControl from './createReturn/stepControl.EU';
import CNReturnStepControl from './createReturn/stepControl.CN';
import JPReturnStepControl from './createReturn/stepControl.JP';
import KRReturnStepControl from './createReturn/stepControl.KR';
import translations from './dialog.i18n';
import DiscountShippingStepControl from './discountShipping/stepControl';
import InspectStep1 from './inspectReturn/step1';
import InspectStep2 from './inspectReturn/step2';
import InspectStepControl from './inspectReturn/stepControl';
import ModifyPriceStep1 from './modifyPrice/step1';
import ModifyPriceStep2 from './modifyPrice/step2';
import ModifyPriceStepControl from './modifyPrice/stepControl';
import { determineIfOrderAllowsReturnLabel } from '../../../utils/order';
import { PartnersExcludedFromDetailsInEMEA } from '../../../constants/origin.const';
import useMemoTranslations from '../../../hooks/useMemoTranslations';
import { hasAndIsKoreanWarehouseWorkerOnly } from '../../../utils/adGroups';

/*
  transition for the mounting/dismounting of the dialog component
  TODO: fix bug where passing {... props} to Slide also passes props to children created by 
  transition.  Causes "non-boolean attribute" warning in console.
   */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

/**
 * Dialog container for handling different dialog types
 */
export default function DialogContainer() {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { hasPermission } = useHasPermission();
  const { authState } = useOktaAuth();
  const { accessToken } = authState;
  const {
    ADD_COMMENT,
    ARIA_RETURN_STEPS,
    ARIA_CANCEL_STEPS,
    ARIA_INSPECTION_STEPS,
    ARIA_MODIFY_PRICE_STEPS,
    ARIA_RESEND_STEPS,
    ARIA_DIALOG_HEADER,
    ADD_ITEM_DETAILS,
    DISCOUNT_SHIPPING,
    EXIT,
    CANCEL_ITEMS,
    CREATE_A_RETURN,
    INSPECT_RETURN,
    MODIFY_ITEM_PRICE,
    SELECT_ITEMS_TO_CANCEL,
    RESEND_RETURN_LABEL,
    SELECT_ITEMS_TO_RETURN,
    SELECT_REASON_FOR_DISCOUNT,
    ADD_RETURNEE_DETAILS,
    ADD_RETURN_LABEL_DETAILS,
    SELECT_ITEMS_TO_INSPECT,
    SELECT_ITEMS_TO_MODIFY_PRICE,
    ACTION_COMPLETE,
    CLOSE_TAB,
    RETURN_ORDER,
    PENDING_MOD_MESSAGE,
    ARIA_EXCHANGE_STEPS,
    CREATE_EXCHANGE,
    VIEW_CART,
    SELECT_ITEMS,
    CHOOSE_NEW,
    REVIEW_ORDER,
    RETURN_EXCEPTION_HANDLING,
    PICKUP_OR_NONPICKUP_LABEL,
  } = useMemoTranslations(translations);

  const { dialogType, isOpen, activeStep, lock } = dialogState;
  const geo = orderDetail.omsRegionReference;
  const returnLabelCreationAllowed = determineIfOrderAllowsReturnLabel(orderDetail);
  const handleCloseDialog = () => {
    // if we're locked, don't close the dialog
    !lock && dialogDispatch(dialogActions.close());
  };

  // Function that returns step control based on the GEO
  const GetStepControl = () => {
    // checking if geo is one of the emea partner enterprises
    if (PartnersExcludedFromDetailsInEMEA.has(geo)) {
      return <EUReturnStepControl />;
    }
    switch (geo) {
      case Geo.US: {
        return <USReturnStepControl />;
      }
      case Geo.EUROPE: {
        return <EUReturnStepControl />;
      }
      case Geo.CHINA: {
        return <CNReturnStepControl />;
      }
      case Geo.JAPAN: {
        return <JPReturnStepControl />;
      }
      case Geo.KOREA: {
        return <KRReturnStepControl />;
      }
      default: {
        return <USReturnStepControl />;
      }
    }
  };

  const GetReturnStep3 = () => {
    switch (geo) {
      case Geo.US: {
        return <ReturnStep3 />;
      }
      case Geo.CHINA: {
        return <ChinaReturnStep3 />;
      }
      case Geo.KOREA: {
        return <KoreaReturnStep3 />;
      }
      case Geo.EUROPE: {
        // If we have an EMEA partner that doesn't allow return labels, we want to skip step 3
        return returnLabelCreationAllowed ? <ReturnStep3 /> : <ReturnStep4 />;
      }
      default: {
        return <ReturnStep3 />;
      }
    }
  };

  // modification pending message conditionally shown in cancel and shipping discount dialogs
  const ModPendingMsg = () => {
    return (
      <Card className={classes.cardSpacing} elevation={3}>
        <CardContent className={classes.cardContentHold}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            data-testid={`order-mod-pending-dialog-message`}
            title={
              <h2 className={classes.cardHeadingHold}>
                {orderDetail.pendingModification.type} {PENDING_MOD_MESSAGE}
              </h2>
            }
          />
        </CardContent>
      </Card>
    );
  };

  const getDialogDetails = () => {
    // Adding this method as we will be having different names for step3 based on Geo's very soon.
    const getStepName = () => {
      // skipping part 3 for emea detail we are adding return label details instead
      if (geo === Geo.KOREA) {
        const { groups } = decodeToken(accessToken?.accessToken);
        if (hasAndIsKoreanWarehouseWorkerOnly(groups)) {
          return RETURN_EXCEPTION_HANDLING;
        } else {
          return PICKUP_OR_NONPICKUP_LABEL;
        }
      } else if (geo === Geo.EUROPE) {
        return returnLabelCreationAllowed ? ADD_RETURNEE_DETAILS : ADD_RETURN_LABEL_DETAILS;
      } else {
        return ADD_RETURNEE_DETAILS;
      }
    };

    switch (dialogType) {
      case DialogTypes.ADD_COMMENT: {
        return {
          label: ADD_COMMENT,
          content: hasPermission(AddComment) ? (
            <div style={{ marginLeft: 50, marginTop: 30 }}>
              <CommentStep1 />
            </div>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.RETURN: {
        return {
          label: CREATE_A_RETURN,
          content: hasPermission(CreateReturn) ? (
            <Stepper
              aria-label={ARIA_RETURN_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_RETURN} key={SELECT_ITEMS_TO_RETURN}>
                <StepLabel>{SELECT_ITEMS_TO_RETURN}</StepLabel>
                <StepContent>
                  <ReturnStep1 />
                  {GetStepControl()}
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <ReturnStep2 />
                  {GetStepControl()}
                </StepContent>
              </Step>
              {/* step 3 */}
              <Step aria-label={getStepName()} key={getStepName()}>
                <StepLabel data-testid='step-3-label'>{getStepName()}</StepLabel>
                <StepContent>
                  {GetReturnStep3()}
                  {GetStepControl()}
                </StepContent>
              </Step>
              {/* step 4 */}
              {returnLabelCreationAllowed && (
                <Step aria-label={ADD_RETURN_LABEL_DETAILS} key={ADD_RETURN_LABEL_DETAILS}>
                  <StepLabel>{ADD_RETURN_LABEL_DETAILS}</StepLabel>
                  <StepContent>
                    <ReturnStep4 />
                    {GetStepControl()}
                  </StepContent>
                </Step>
              )}
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.CANCEL: {
        return {
          label: CANCEL_ITEMS,
          content: hasPermission(CancelOrders) ? (
            <DialogContent>
              {orderDetail?.pendingModification && <ModPendingMsg />}
              <Stepper
                aria-label={ARIA_CANCEL_STEPS}
                activeStep={activeStep}
                orientation='vertical'
                active='true'
                last='false'
                completed='false'>
                {/* step 1 */}
                <Step aria-label={SELECT_ITEMS_TO_CANCEL} key={SELECT_ITEMS_TO_CANCEL}>
                  <StepLabel>{SELECT_ITEMS_TO_CANCEL}</StepLabel>
                  <StepContent>
                    <CancelStep1 />
                    <CancelStepControl />
                  </StepContent>
                </Step>
                {/* step 2 */}
                <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                  <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                  <StepContent>
                    <CancelStep2 />
                    <CancelStepControl />
                  </StepContent>
                </Step>
              </Stepper>
            </DialogContent>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.INSPECT: {
        return {
          label: INSPECT_RETURN,
          content: hasPermission(
            orderDetail?.omoboFlags?.isReInspectable ? ReinspectReturn : InspectReturn
          ) ? (
            <Stepper
              aria-label={ARIA_INSPECTION_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_INSPECT} key={SELECT_ITEMS_TO_INSPECT}>
                <StepLabel>{SELECT_ITEMS_TO_INSPECT}</StepLabel>
                <StepContent>
                  <InspectStep1 />
                  <InspectStepControl />
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <InspectStep2 />
                  <InspectStepControl />
                </StepContent>
              </Step>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.DISCOUNT_SHIPPING: {
        return {
          label: DISCOUNT_SHIPPING,
          content: hasPermission(DiscountShipping) ? (
            <DialogContent>
              {orderDetail?.pendingModification && <ModPendingMsg />}
              <Stepper
                aria-label={ARIA_CANCEL_STEPS}
                activeStep={activeStep}
                orientation='vertical'
                active='true'
                last='false'
                completed='false'>
                {/* step 1 */}
                <Step aria-label={SELECT_REASON_FOR_DISCOUNT} key={SELECT_REASON_FOR_DISCOUNT}>
                  <StepLabel>{SELECT_REASON_FOR_DISCOUNT}</StepLabel>
                  <StepContent>
                    <DiscountShippingStepControl />
                  </StepContent>
                </Step>
              </Stepper>
            </DialogContent>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.RESEND_RETURN_LABEL: {
        return {
          label: RESEND_RETURN_LABEL,
          content: hasPermission(SendResendReturnLabel) ? (
            <Stepper
              aria-label={ARIA_RESEND_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'
              data-testid='resend-return-label-stepper'>
              <StepContent>
                <ReturnStep4 />
                {GetStepControl()}
              </StepContent>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.MODIFY_PRICE: {
        return {
          label: MODIFY_ITEM_PRICE,
          content: hasPermission(ModifyPrice) ? (
            <Stepper
              aria-label={ARIA_MODIFY_PRICE_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_MODIFY_PRICE} key={SELECT_ITEMS_TO_MODIFY_PRICE}>
                <StepLabel>{SELECT_ITEMS_TO_MODIFY_PRICE}</StepLabel>
                <StepContent>
                  <ModifyPriceStep1 />
                  <ModifyPriceStepControl />
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <ModifyPriceStep2 />
                  <ModifyPriceStepControl />
                </StepContent>
              </Step>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.EXCHANGE: {
        return {
          label: CREATE_EXCHANGE,
          content: hasPermission(ExchangeProduct) ? (
            <Box>
              <Stepper
                style={{ width: '50%', margin: '0 auto' }}
                aria-label={ARIA_EXCHANGE_STEPS}
                activeStep={activeStep}
                active='true'
                last='false'
                alternativeLabel
                completed='false'>
                {/* step 1 */}
                <Step aria-label={SELECT_ITEMS} key={SELECT_ITEMS}>
                  <StepLabel>{SELECT_ITEMS}</StepLabel>
                </Step>
                {/* step 2 */}
                <Step aria-label={CHOOSE_NEW} key={CHOOSE_NEW}>
                  <StepLabel>{CHOOSE_NEW}</StepLabel>
                </Step>
                {/* step 3 */}
                <Step aria-label={VIEW_CART} key={VIEW_CART}>
                  <StepLabel>{VIEW_CART}</StepLabel>
                </Step>
                {/* step 4 */}
                <Step aria-label={REVIEW_ORDER} key={REVIEW_ORDER}>
                  <StepLabel>{REVIEW_ORDER}</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 && <ExchangeStep1 />}
              {activeStep === 1 && <ExchangeStep2 />}
              {activeStep === 2 && <ExchangeStep3 />}
              {activeStep === 3 && <ExchangeStep4 />}
              <ExchangeStepControl />
            </Box>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.ACTION_COMPLETE: {
        return {
          label: ACTION_COMPLETE,
          content: (
            <Typography variant='subtitle1'>
              {CLOSE_TAB}
              {dialogState.returnOrderNumber &&
                ` ${RETURN_ORDER}: ${dialogState.returnOrderNumber}`}
            </Typography>
          ),
        };
      }
      default: {
        return {};
      }
    }
  };

  const dialogDetails = getDialogDetails();
  return (
    dialogType &&
    orderDetail && (
      <Dialog
        fullScreen
        open={lock || isOpen}
        onClose={handleCloseDialog}
        aria-labelledby='dialogTitle'
        TransitionComponent={!lock ? Transition : undefined}
        classes={{ root: classes.dialogRoot, paper: classes.noScroll }}>
        <div aria-label={ARIA_DIALOG_HEADER} className={classes.dialogBar}>
          <ErrorBoundary>
            <Toolbar>
              <Typography
                variant='h1'
                id='dialogTitle'
                color='inherit'
                className={classes.label}
                data-testid={`dialog-${dialogDetails.label}`}>
                {dialogDetails.label}
              </Typography>
              {// if we're locked, don't show the exit button
              !lock && (
                <Button
                  data-testid='exit-dialog-button'
                  color='inherit'
                  aria-label={EXIT.toLowerCase()}
                  onClick={handleCloseDialog}>
                  {EXIT}
                </Button>
              )}
            </Toolbar>
          </ErrorBoundary>
        </div>
        {dialogDetails.content}
      </Dialog>
    )
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.2rem',
    fontWeight: '500',
    flex: 1,
  },
  dialogRoot: { zIndex: `4 !important` },
  dialogBar: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
  },
  noScroll: { overflowX: 'hidden' },
  cardSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
  },
  cardHeaderRoot: {
    paddingLeft: theme.spacing(1),
  },
  cardHeadingHold: {
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
    color: theme.palette.error.main,
  },
  cardContentHold: {
    'overflow': 'auto',
    'paddingTop': '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));
