/* React/Utils */
import React, { useState, useEffect, useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';

/* Local */
import { DialogContext } from './../../store/contexts/dialogContext';
import { actions as dialogActions } from './../../store/actions/dialogActions';
import translations from './addressValidation.i18n';
import { AddressTypes, AddressValidationStatus } from './../../constants/dialog.const';
import useHasPermission from './../../hooks/useHasPermission';
import { SendResendReturnLabel } from './../../constants/permissions.const';
import { prepareAddressObject } from '../../utils/address';

/**
 * Address Validator component that shows suggested address and entered address.
 * Call this component When the address that is entered/available
 * in the application is not matching with the address from addressValidator api.
 */
export default function AddressValidator({
  status,
  address: suggestedAndEnteredAddress,
  createReturnOrder,
  endOnActiveStep2 = false,
}) {
  const classes = useStyles();
  const { hasPermission } = useHasPermission();
  const { i18nString } = useContext(NikeI18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { setAddress, setGiftCardAddress, nextStep, setValidateAddress } = dialogActions;
  const [addressTypeSelected, setAddressTypeSelected] = useState(AddressTypes.SUGGESTED_ADDRESS);
  const { suggestedAddress, addressEntered } = suggestedAndEnteredAddress;
  const { address, gcShippingAddress, activeStep, validateAddress } = dialogState;
  const {
    ARIA_SUGGESTED_ADDRESS,
    ARIA_SELECT_ADDRESS,
    SUGGESTED_ADDRESS,
    ADDRESS_ENTERED,
    WARNING_CLOSE_MATCH,
    WARNING_NO_MATCH,
    CONFIRM_ADDRESS,
    EDIT_ADDRESS,
    SAVE_AND_CONTINUE,
    SAVE_AND_CREATE_RETURN_ADDRESS,
  } = mapValues(translations, i18nString);
  const suggestedAddressItems = {
    header: SUGGESTED_ADDRESS,
    value: AddressTypes.SUGGESTED_ADDRESS,
    addressMap: prepareAddressObject(suggestedAddress),
  };

  const enteredAddressItems = {
    header: ADDRESS_ENTERED,
    value: AddressTypes.ADDRESS_ENTERED,
    addressMap: prepareAddressObject(addressEntered),
  };

  /**
   * This useEffect will set the address/gift card address to suggested address if the
   * address validation status is verified. We need to do this one time when the
   * address component loads so that the form has the address that is currently selected
   * in address validation component (By default suggested address will be selected when
   * address component loads).
   */
  useEffect(() => {
    if (status === AddressValidationStatus.VERIFIED) {
      if (activeStep === 2)
        dialogDispatch(setGiftCardAddress({ ...gcShippingAddress, ...suggestedAddress }));
      else dialogDispatch(setAddress({ ...address, ...suggestedAddress }));
    }
  }, []);

  /**
   * Update local state on address selection
   * @param {*} event - event triggered on radio button toggle
   */
  const handleRadioChange = (event) => {
    setAddressTypeSelected(event.target.value);
    if (activeStep === 2) {
      dialogDispatch(setGiftCardAddress(getUpdatedAddress(event, gcShippingAddress)));
    } else {
      dialogDispatch(setAddress(getUpdatedAddress(event, address)));
    }
  };

  /**
   * Updates the dialog state with the selected address and initiate return  process
   */
  const handleSubmit = (e) => {
    e.preventDefault(); // gets rid of form not connected console warning
    if (isReadyToCreateReturn()) {
      createReturnOrder();
    } else {
      dialogDispatch(nextStep());
    }
    dialogDispatch(setValidateAddress(false));
  };

  /**
   * Closes the address validation component
   */
  const handleClose = () => {
    dialogDispatch(setValidateAddress(false));
  };

  const isReadyToCreateReturn = () => {
    if (dialogState.activeStep === 2 && endOnActiveStep2) {
      return true;
    } else if (dialogState.activeStep === 2 && hasPermission(SendResendReturnLabel)) {
      return false;
    }
    return true;
  };

  /**
   * This function returns the updated address based on the option selected from UI
   * Options: Suggested address/ Address entered
   * @param {*} event: event triggered on selecting address options from UI
   * @param {*} addressToBeUpdated: Gift card Address/ customer address
   */
  const getUpdatedAddress = (event, addressToBeUpdated) => {
    if (event.target.value === AddressTypes.SUGGESTED_ADDRESS)
      return { ...addressToBeUpdated, ...suggestedAddress };
    return { ...addressToBeUpdated, ...addressEntered };
  };

  const AddressInfo = (props) => {
    const { header, value, addressMap } = props.addressItems;
    return (
      <>
        <FormHelperText className={classes.addressHeaderText}>{header}</FormHelperText>
        <Card className={classes.innerCard} variant='outlined'>
          <CardContent>
            <FormControlLabel
              value={value}
              control={<Radio />}
              label={addressMap.map((value, key) => {
                return (
                  <Typography
                    className={classes.address}
                    key={key}
                    data-testid={
                      value === AddressTypes.SUGGESTED_ADDRESS
                        ? `suggested-address-${key}`
                        : `entered-address-${key}`
                    }>
                    {value}
                  </Typography>
                );
              })}
            />
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby='validatorTitle' open={validateAddress}>
      <Box className={classes.addressValidator}>
        <Typography
          id='validatorTitle'
          aria-label={CONFIRM_ADDRESS.toLowerCase()}
          className={classes.header}
          variant='h5'
          gutterBottom>
          {CONFIRM_ADDRESS}
        </Typography>
        <Typography variant='body2' className={classes.infoText}>
          {status === AddressValidationStatus.VERIFIED ? WARNING_CLOSE_MATCH : WARNING_NO_MATCH}
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl
            aria-label={ARIA_SUGGESTED_ADDRESS}
            legend='suggested address'
            component='fieldset'
            className={classes.formControl}>
            <RadioGroup
              aria-label={ARIA_SELECT_ADDRESS}
              name='addressSuggestion'
              value={
                status === AddressValidationStatus.VERIFIED
                  ? addressTypeSelected
                  : AddressTypes.ADDRESS_ENTERED
              }
              className={classes.radioGroup}
              onChange={handleRadioChange}>
              {status === AddressValidationStatus.VERIFIED ? (
                <>
                  <AddressInfo addressItems={suggestedAddressItems} />
                  <AddressInfo addressItems={enteredAddressItems} />
                </>
              ) : (
                <AddressInfo addressItems={enteredAddressItems} />
              )}
            </RadioGroup>
            <div className={classes.buttonFrame}>
              <Button type='reset' onClick={handleClose} className={classes.addressButton}>
                {EDIT_ADDRESS}
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.addressButton}
                data-testid='save-address-button'>
                {isReadyToCreateReturn() ? SAVE_AND_CREATE_RETURN_ADDRESS : SAVE_AND_CONTINUE}
              </Button>
            </div>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  addressValidator: {
    padding: theme.spacing(3),
  },
  header: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  buttonFrame: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
  },
  infoText: { textAlign: 'center' },
  formControl: { width: '100%' },
  addressHeaderText: {
    marginTop: 25,
    marginBottom: 25,
    fontWeight: 700,
    fontSize: '4/3rem',
  },
  innerCard: {
    alignContent: 'center',
    marginLeft: 0,
    minWidth: 450,
    maxWidth: 500,
  },
  addressButton: {
    marginRight: '16px',
  },
  address: {
    paddingLeft: '12px',
    lineHeight: 1.8,
  },
  radioGroup: {
    alignContent: 'center',
  },
}));
