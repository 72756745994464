/** React / Utils */
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TodayIcon from '@material-ui/icons/Today';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

/** Local */
import translations from './itemDetails.i18n';
import Codes from '../../../../codes/codes';
import {
  ReasonCodeTypes,
  ReasonCodeAppId,
  CancelReasonCodes,
} from '../../../../constants/dialog.const';
import { ViewFullGiftCardNumber } from '../../../../constants/permissions.const';
import useHasPermission from '../../../../hooks/useHasPermission';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import BasicTableRow from '../../../shared/table/tableRow';
import BasicTableRowWithNestedRows from '../../../shared/table/tableRowWithNestedRows';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import NewTabButton from '../../../shared/newTabButton';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { OrderContext } from '../../../../store/contexts/orderContext';
import config from '../../../../utils/config';
import { formatDateTime, formatDate } from '../../../../utils/date';
import {
  getCharges,
  getDiscount,
  getDisplaySize,
  getOrderlineStatusCode,
  getTax,
  getTaxDiscounts,
} from '../../../../utils/order';
import { isLineItemGiftCard } from '../../../../utils/orderLine';
import { getReturnedAtStoreInfo } from '../../../../utils/orderLine';
import { appropriateLanguage } from '../../../../utils/language';
import { getUnitPrice } from '../../../../utils/price';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import ExpansionButton from './expansionButton';
import useEnrichedOrderLines from './hooks/useEnrichedOrderLines';
import StyleLink from './itemLink';
import { displaySDDRegions } from '../../../../constants/order.const';

/**
 * Main react component housing the card content for item details tab, each item can be expanded,
 * showing the Expanded Item component
 */
function ItemDetails({ allOrderLines, csOrderSummary, orderLines, ExpandedContent, shipment }) {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [i18State] = useContext(I18nContext);
  const [expandedItems, setExpandedItems] = useState([]);
  const { hasPermission } = useHasPermission();
  const [dialogState] = useContext(DialogContext);
  const [assets] = useContext(AssetsContext);
  const { isMilitaryAddress } = dialogState;

  const { currency, orderType } = orderDetail;
  const displaySDD = displaySDDRegions.includes(orderDetail.omsRegionReference);
  const {
    ARIA_EXPANDED_ITEM_DETAIL,
    ARIA_REASON_CODES_LOADING,
    CANCEL_REASON,
    CANCEL_REQUESTED,
    HOLD,
    INSPECTION_REASON,
    LINE_TOTAL,
    UNIT_PRICE,
    QUANTITY,
    RETURN_REASON,
    SIZE,
    STATUS,
    EDD,
    FULFILLER,
    STYLE_NAME,
    STYLE_COLOR,
    STORE_INFO_NOT_AVAILABLE,
    TO_GIFT_CARD_APP,
    RETURNED_AT_STORE_NUMBER,
    RETURN_DATE,
    TRANSACTION_ID,
    REFUND_PAYMENT_TYPE,
    PENDING_CANCEL,
    SDD,
  } = useMemoTranslations(translations);

  const [
    fetchReturnReasonCodes,
    { data: reasonCodeData, loading: reasonCodeLoading },
  ] = useLazyQuery(REASON_CODES_QUERY, {
    variables: {
      appId: ReasonCodeAppId,
      omsRegionReference: orderDetail.omsRegionReference,
      type: ReasonCodeTypes.RETURN,
      language: appropriateLanguage(i18State.language),
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    fetchCancelReasonCodes,
    { data: cancelCodeData, loading: cancelCodeLoading },
  ] = useLazyQuery(REASON_CODES_QUERY, {
    variables: {
      appId: ReasonCodeAppId,
      omsRegionReference: orderDetail.omsRegionReference,
      type: orderType === 'SALES_ORDER' ? ReasonCodeTypes.CANCEL : ReasonCodeTypes.RETURN_CANCEL,
      language: appropriateLanguage(i18State.language),
    },
    notifyOnNetworkStatusChange: true,
  });

  /*
    The retrieval of reason codes is something we want to do only when necessary. The
    use of the conditional, useEffect and useLazyQuery will allow us to do this.
   */
  useEffect(() => {
    if (orderType === 'RETURN_ORDER') fetchReturnReasonCodes();
    if (orderHasCancellation && !allReasonsInDictionary()) fetchCancelReasonCodes();
  }, []);

  // checks if all cancel reasons are in reason codes dictionary to save service call if so
  const allReasonsInDictionary = () => {
    return orderLines.every((ol) => {
      return CancelReasonCodes.hasOwnProperty(ol.cancellationReason);
    });
  };

  const getFulfiller = (lineStatuses) => {
    if (lineStatuses?.shipAdviceNumber) {
      return `${lineStatuses.shipNode}-${lineStatuses.shipAdviceNumber}`;
    } else {
      return (
        lineStatuses.shipNode +
        (lineStatuses?.contractNumber ? ` - ${lineStatuses?.contractNumber}` : '') +
        (lineStatuses?.chainedOrderNumber ? ` (${lineStatuses?.chainedOrderNumber})` : '')
      );
    }
  };

  /**
   * Enrich orderLines with pickup point or store location data if necessary for each orderLine
   */
  useEnrichedOrderLines(orderLines);

  const toggleExpansion = (omoboUniqueIdentifier) => {
    setExpandedItems((expandedItems) =>
      expandedItems.includes(omoboUniqueIdentifier)
        ? expandedItems.filter((item) => item !== omoboUniqueIdentifier)
        : [...expandedItems, omoboUniqueIdentifier]
    );
  };

  /**
   * Generates the header row of the table based on the order type as well as other
   * conditions based on the order that will change the columns that are displayed.
   *
   * @param {string} orderType type of order. ex: sales order or return order.
   * @param {boolean} isInspected  specific to return order, whether the returned item has
   * been inspected.
   * @param {boolean} hasItemCancellation whether or not there is an item that is in the
   * cancelled status.
   * @returns
   */
  const generateTableHeaderRow = (orderType, isInspected, hasItemCancellation) => {
    const baseHeaders = [STYLE_NAME, STYLE_COLOR, SIZE, UNIT_PRICE, QUANTITY, STATUS];
    const addOn = [];
    switch (orderType) {
      case 'RETURN_ORDER':
        addOn.push(RETURN_REASON);
        if (hasItemCancellation) addOn.push(CANCEL_REASON);
        if (isInspected) addOn.push(INSPECTION_REASON);
        break;
      default:
        if (orderHasFulfiller) addOn.push(FULFILLER);
        if (hasItemCancellation) addOn.push(CANCEL_REASON);
        addOn.push(EDD);
        // Display SDD header only for Japan orders
        displaySDD ? addOn.push(SDD) : addOn.push('');
        break;
    }
    addOn.push(LINE_TOTAL);

    // the null is for the action buttons column.
    return [...baseHeaders, ...addOn, null];
  };

  const generateCancelReason = (statusCode) => {
    // check if code is in reason codes dictionary
    if (CancelReasonCodes[statusCode]) {
      return (
        // returns english as default in case of no language or other weirdness
        CancelReasonCodes[statusCode][appropriateLanguage(i18State.language)] ||
        CancelReasonCodes[statusCode]['en-US']
      );
    } else {
      // if not, get from reason codes response
      const cancellationReason = cancelCodeData?.reasonCodesV2?.reasons?.find(
        (reason) => reason.code === statusCode
      );
      return cancellationReason?.description || isStillLoading(cancelCodeLoading);
    }
  };

  const generateLineStatusDescForSaleOrder = (line, csOrderSummary, i, orderLines) => {
    const {
      storeNumber,
      transactionNumber,
      paymentType,
      transactionBeginDate,
    } = getReturnedAtStoreInfo(line.orderLineKey, csOrderSummary);

    // if holds are available and not resolved, set addHolds
    const addHolds = Boolean(line.lineHolds && line.lineHolds[0].status !== 'Resolved');
    const hasCancelRequests = Boolean(line.cancelRequestQuantity);
    const missingAllDataTooltip = !(
      storeNumber &&
      transactionNumber &&
      transactionBeginDate &&
      paymentType
    );

    return (
      Array.isArray(line.statuses) &&
      line.statuses.length && (
        <>
          <Typography className={classes.lineStatus}>
            {hasCancelRequests && `${line.quantity - line.cancelRequestQuantity} - `}

            {allOrderLines &&
            orderLines.some((order) => {
              return order.orderLineKey.includes('-');
            })
              ? allOrderLines.map((order) => {
                  return (
                    <div>
                      {order.orderLineKey.split('-')[0] === line.orderLineKey.split('-')[0] &&
                        order.quantity + ' ' + order.statuses[line.statuses.length - 1].description}
                    </div>
                  );
                })
              : line.statuses[line.statuses.length - 1].description}
            {// check if this line has a pending cancel status
            orderDetail.pendingModification?.type.includes('CANCEL') &&
              getOrderlineStatusCode(line).includes('.9000') && (
                <Tooltip title={<div data-testid='pending-cancel-tooltip'>{PENDING_CANCEL}</div>}>
                  <WarningIcon
                    name='pending-warning'
                    className={clsx(classes.icon, 'warning')}
                    data-testid='pending-cancel-icon'
                  />
                </Tooltip>
              )}
            {line.statuses[line.statuses.length - 1].description === 'Returned At Store' && (
              <Tooltip
                className={classes.returnedAtStore}
                title={
                  <div data-testid='returned-at-store-tooltip'>
                    <List className={classes.tooltip}>
                      {storeNumber && (
                        <ListItem>
                          {RETURNED_AT_STORE_NUMBER}: {storeNumber}
                        </ListItem>
                      )}
                      {transactionNumber && (
                        <ListItem>
                          {TRANSACTION_ID}: {transactionNumber}
                        </ListItem>
                      )}
                      {transactionBeginDate && (
                        <ListItem>
                          {RETURN_DATE}: {formatDate(transactionBeginDate, orderDetail.locale)}
                        </ListItem>
                      )}
                      {paymentType && (
                        <ListItem>
                          {REFUND_PAYMENT_TYPE}: {paymentType}
                        </ListItem>
                      )}
                      {missingAllDataTooltip && <ListItem>{STORE_INFO_NOT_AVAILABLE}</ListItem>}
                    </List>
                  </div>
                }>
                <InfoIcon className={classes.icon} data-testid={`returned-at-store-icon`} />
              </Tooltip>
            )}
            {/* Add in holdText and calendar icon if applicable */}
            {addHolds && (
              <>
                <div className={classes.holdText} data-testid={`line-hold-${i}`}>
                  {` ${line.lineHolds[0].type} ${HOLD} `}
                </div>
                <Tooltip
                  title={
                    <span className={classes.tooltip}>
                      {formatDateTime(line.lineHolds[0].lastHoldDate, orderDetail.locale)}
                    </span>
                  }>
                  <TodayIcon name='hold' className={clsx(classes.icon, 'hold')} />
                </Tooltip>
              </>
            )}
          </Typography>
          {hasCancelRequests && (
            <Typography className={classes.lineStatus}>
              {line.cancelRequestQuantity} - {CANCEL_REQUESTED}
            </Typography>
          )}
        </>
      )
    );
  };

  const columnClassNames = [
    classes.styleNameColumn,
    classes.sizeColumn,
    classes.unitPriceColumn,
    classes.quantityColumn,
    classes.statusColumn,
    classes.returnColumn,
    classes.cancelReasonColumn,
    classes.inspectionColumn,
    classes.lineTotalColumn,
    classes.lastColumn,
  ];

  let regularOrderLines = orderLines.filter((orderLine) => {
    return orderLine.orderLineType !== 'SERVICE';
  });

  const orderHasCancellation = regularOrderLines.some((ol) => {
    return ol.statuses?.some((code) => code['statusCode'] === '9000');
  });

  const orderHasFulfiller = regularOrderLines?.some((ol) => {
    return ol.statuses?.some(
      ({ shipAdviceNumber, shipNode, contractNumber, chainedOrderNumber }) => {
        return shipNode || (contractNumber && chainedOrderNumber) || shipAdviceNumber;
      }
    );
  });

  const orderIsInspected = orderDetail.status?.includes('Inspect');
  const isShipmentOrderLines = !!shipment;

  /**
   * To be called in the event that the reason code description is not avaible. If
   * the reason code retrieval is still loading, we display a loading icon, if not
   * then we display a '-' because the order line itself should not disappear due to
   * the lack of reason codes.
   * @param {boolean} isLoadingCondition boolean condition used to determine if the
   * query is still loading.
   * @returns Either a string or a circular progress bar.
   */
  const isStillLoading = (isLoadingCondition) => {
    return isLoadingCondition ? (
      <CircularProgress aria-label={ARIA_REASON_CODES_LOADING} className={classes.loading} />
    ) : (
      '-'
    );
  };

  const calculatelineTotal = (line) => {
    if (
      allOrderLines &&
      orderLines.some((order) => {
        return order.orderLineKey.includes('-');
      })
    ) {
      return allOrderLines.reduce((acc, order) => {
        const price =
          order.orderLineKey.split('-')[0] === line.orderLineKey.split('-')[0] &&
          order.lineTotal - getTaxDiscounts(line);
        return acc + (price ?? 0);
      }, 0);
    } else if (line.statuses.length > 1 && isShipmentOrderLines) {
      return shipment.containers.reduce((acc, { shipmentLines }) => {
        // for items that are part of same order line but shipped separately,
        // calculate line total based on the quantity of each
        const price = shipmentLines.reduce(
          (acc, { orderLineIdentifier, quantity }) =>
            acc +
            ((line.orderLineType === 'NIKEID'
            ? line.nikeIdLines.some((line) => line.orderLineKey.includes(orderLineIdentifier))
            : line.orderLineKey.includes(orderLineIdentifier))
              ? (line.lineTotal / line.quantity) * quantity - getTaxDiscounts(line)
              : 0),
          0
        );
        return acc + (price ?? 0);
      }, 0);
    } else {
      return line && line.lineTotal - getTaxDiscounts(line);
    }
  };

  return (
    <Table className={classes.itemDetailTable}>
      <TableHead>
        <BasicTableRow
          header
          rowClassName={classes.headerRow}
          data={generateTableHeaderRow(orderType, orderIsInspected, orderHasCancellation)}
          cellClassName={columnClassNames}
          cellRootClassName={classes.itemDetailCellRoot}
          testId='item-details-header'
        />
      </TableHead>
      {regularOrderLines.map((line, i) => {
        const lineNumber = line?.lineNumber;
        const omoboUniqueIdentifier = line?.omoboUniqueIdentifier;
        const unitPrice = getUnitPrice(line);
        const unitPriceTotal = unitPrice * line.quantity;
        const militaryDiscount = isMilitaryAddress ? getDiscount(line) : 0;
        const charges = getCharges(line);
        const isGiftCardLine = isLineItemGiftCard(line);
        const isMultiQtyGcLine = isGiftCardLine && line.quantity > 1;

        const statusCodes = orderLines
          .map((line) => line.statuses)
          .flat()
          .map((status) => status?.statusCode);

        const isItemCancelled = statusCodes?.some((code) => code?.startsWith('9000'));

        // If line status is cancelled, then we create the lineTotal ourselves
        const lineTotal = isItemCancelled
          ? unitPriceTotal - getTaxDiscounts(line) - militaryDiscount + charges + getTax(line)
          : calculatelineTotal(line);

        // find gift card image according to color code
        const giftCardImage =
          (line.styleNumber === 'GIFTCARD' && getProductImageFromOrderLine(line, assets)) || '';

        // here we will put actual data for the table
        let data = [];
        /*
          nested conditions to determine display of return
          and inspection reasons on order details
        */
        if (orderType === 'RETURN_ORDER') {
          const returnReason = reasonCodeData?.reasonCodesV2.reasons.find(
            (reason) => reason.code === line.returnReason
          );

          // add in the data which are common to all return orders
          data.push(
            line.item && line.item.itemDescription,
            <StyleLink orderDetail={orderDetail} line={line} />,
            getDisplaySize(line),
            line.linePriceInformation && (
              <FormattedCurrency amount={line.linePriceInformation.unitPrice} currency={currency} />
            ),
            line.quantity,
            Array.isArray(line.statuses) && line.statuses.length && line.statuses[0].description,
            returnReason?.description || isStillLoading(reasonCodeLoading),
            ...(orderHasCancellation ? [generateCancelReason(line.cancellationReason)] : [])
          );
          if (orderIsInspected) {
            /*
               if the return has been inspected, grab the ID of the inspection reason,
               and look up its description in the Codes library
               */
            if (line.refundReason != null) {
              const inspectionReason = Codes[line?.refundReason]?.longDescription;
              data.push(inspectionReason);
            } else {
              data.push('');
            }
          }
          // finally, add the common final items
          data.push(
            <FormattedCurrency amount={line.lineTotal} currency={currency} />,
            <ExpansionButton
              expanded={expandedItems.includes(omoboUniqueIdentifier)}
              onClick={() => toggleExpansion(omoboUniqueIdentifier)}
              data-testid={`see-hide-detail-button-${i + 1}`}
            />
          );
        } else {
          const { statuses, fulfillmentMethod, linePriceInformation } = line;
          const lineStatuses = statuses?.[statuses.length - 1];
          const fulfiller = getFulfiller(lineStatuses);
          data.push(
            isGiftCardLine
              ? line.giftCardDetail?.giftCards?.map(
                  (gc, i) =>
                    gc.giftCardNumber && (
                      <ListItem key={i} className={classes.giftCardLinkList}>
                        <NewTabButton
                          ariaLabel={TO_GIFT_CARD_APP}
                          label={
                            hasPermission(ViewFullGiftCardNumber)
                              ? `${fulfillmentMethod}:${gc.giftCardNumber}`
                              : `${fulfillmentMethod}:${gc.giftCardNumber
                                  .slice(gc.giftCardNumber.length - 4)
                                  .padStart(gc.giftCardNumber.length, '*')}`
                          }
                          href={`${config.giftCardUrl}info/${gc.giftCardInfoKey}`}
                        />
                      </ListItem>
                    )
                )
              : line?.item?.itemDescription,
            <StyleLink orderDetail={orderDetail} line={line} />,
            getDisplaySize(line),
            linePriceInformation && (
              <FormattedCurrency amount={linePriceInformation.unitPrice} currency={currency} />
            ),
            isShipmentOrderLines ? (
              /*
               Find out how many items we are rendering (items with a given `.orderLineKey`)
               are present in the rendered shipment
               */
              <span data-testid={`item-details-${i}-quantity`}>
                {/* First, reduce over every container */}
                {shipment.containers.reduce((acc, { shipmentLines }) => {
                  // Then, for each container reduce over every `shipmentLine`
                  const quantity = shipmentLines.reduce(
                    (acc, { orderLineIdentifier, quantity }) =>
                      acc +
                      ((line.orderLineType === 'NIKEID'
                      ? line.nikeIdLines.some((line) =>
                          line.orderLineKey.includes(orderLineIdentifier)
                        )
                      : line.orderLineKey.includes(orderLineIdentifier))
                        ? quantity
                        : 0),
                    0
                  );
                  return acc + (quantity ?? 0);
                }, 0)}
              </span>
            ) : (
              // Sets line quantity of the order line
              line.quantity
            ),
            generateLineStatusDescForSaleOrder(line, csOrderSummary, i, orderLines),
            ...(orderHasFulfiller ? [fulfiller] : []),
            ...(orderHasCancellation ? [generateCancelReason(line.cancellationReason)] : []),
            formatDate(line.estimatedDeliveryDate, orderDetail.locale),
            displaySDD && formatDate(line.scheduledDeliveryStartDate, orderDetail.locale), // Display SDD header only for Japan orders
            <FormattedCurrency amount={lineTotal} currency={currency} />,
            <ExpansionButton
              expanded={expandedItems.includes(omoboUniqueIdentifier)}
              aria-controls={`expanded-item-${lineNumber}`}
              onClick={() => toggleExpansion(omoboUniqueIdentifier)}
              data-testid={`see-hide-detail-button-${i + 1}`}
            />
          );
        }

        return (
          <Fragment key={i}>
            <BasicTableRowWithNestedRows
              data-testid={`order-line-details-${i}`}
              cellClassName={
                line.hasNestedVasOrderLines
                  ? clsx(classes.itemCellWithNoBorder, isMultiQtyGcLine && classes.alignTop)
                  : clsx(classes.itemCellWithBorder, isMultiQtyGcLine && classes.alignTop)
              }
              nestedCellClassName={classes.itemCellWithNoBorder}
              lastNestedCellClassName={classes.itemCellWithBorder}
              rowClassName={classes.itemRow}
              cellRootClassName={classes.itemDetailCellRoot}
              outerIndentationClass={classes.arrowBody}
              innerIndentationClass={classes.arrowHead}
              indentColumnIndex={1}
              data={data}
              nestedData={
                line.nestedVasOrderLines &&
                line.nestedVasOrderLines.map((vasLine) => {
                  return [
                    null,
                    vasLine.item && vasLine.item.itemDescription,
                    vasLine.styleNumber && vasLine.styleNumber + '-' + vasLine.colorCode,
                    null,
                    vasLine.linePriceInformation && (
                      <FormattedCurrency
                        amount={vasLine.linePriceInformation.unitPrice}
                        currency={currency}
                      />
                    ),
                    null,
                    null,
                    null,
                    null,
                  ];
                })
              }
            />
            {expandedItems.includes(omoboUniqueIdentifier) && (
              <TableBody id={`expanded-item-${lineNumber}`}>
                <TableRow>
                  <TableCell
                    colSpan={9}
                    aria-label={ARIA_EXPANDED_ITEM_DETAIL}
                    classes={{ root: classes.itemDetailCellRoot }}>
                    <Collapse in key={i} className={classes.collapsePanel}>
                      <ExpandedContent
                        aria-expanded='true'
                        line={line}
                        currency={currency}
                        giftCardImage={giftCardImage}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Fragment>
        );
      })}
    </Table>
  );
}

ItemDetails.propTypes = {
  allOrderLines: PropTypes.arrayOf(
    PropTypes.shape({
      omoboFlags: PropTypes.shape({
        isPickUpPoint: PropTypes.bool,
        isShipToStore: PropTypes.bool,
      }),
      orderVersion: PropTypes.string,
      refundReason: PropTypes.string,
      styleNumber: PropTypes.string,
      returnReason: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      orderLineType: PropTypes.string,
      lineNumber: PropTypes.number,
      lineTotal: PropTypes.number,
      colorCode: PropTypes.string,
      fulfillmentMethod: PropTypes.string,
      hasNestedVasOrderLines: PropTypes.bool,
      quantity: PropTypes.number,
      displaySize: PropTypes.string,
      giftCardDetail: PropTypes.shape({
        giftCards: PropTypes.arrayOf(
          PropTypes.shape({
            giftCardNumber: PropTypes.string,
          })
        ),
      }),
      linePriceInformation: PropTypes.shape({
        unitPrice: PropTypes.number,
      }),
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        })
      ),
      lineHolds: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string,
          lastHoldDate: PropTypes.string,
        })
      ),
      nestedVasOrderLines: PropTypes.arrayOf(
        PropTypes.shape({
          item: PropTypes.shape({
            itemDescription: PropTypes.string,
          }),
          styleNumber: PropTypes.string,
          colorCode: PropTypes.string,
          linePriceInformation: PropTypes.shape({
            unitPrice: PropTypes.string,
          }),
        })
      ),
    })
  ),
  csOrderSummary: PropTypes.shape({
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        store: PropTypes.arrayOf(
          PropTypes.shape({
            storeNumber: PropTypes.string,
            transactionBeginDate: PropTypes.string,
            transactionNumber: PropTypes.number,
          })
        ),
        paymentMethods: PropTypes.arrayOf(
          PropTypes.shape({
            paymentType: PropTypes.string,
          })
        ),
      })
    ),
  }),
  shipment: PropTypes.shape({
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        shipmentLines: PropTypes.arrayOf(
          PropTypes.shape({
            orderLineIdentifier: PropTypes.string.isRequired,
            quantity: PropTypes.number.isRequired,
          }).isRequired
        ),
      }).isRequired
    ),
  }),
  orderLines: PropTypes.arrayOf(
    PropTypes.shape({
      omoboFlags: PropTypes.shape({
        isPickUpPoint: PropTypes.bool,
        isShipToStore: PropTypes.bool,
      }),
      orderVersion: PropTypes.string,
      refundReason: PropTypes.string,
      styleNumber: PropTypes.string,
      returnReason: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      orderLineType: PropTypes.string,
      lineNumber: PropTypes.number,
      lineTotal: PropTypes.number,
      colorCode: PropTypes.string,
      fulfillmentMethod: PropTypes.string,
      hasNestedVasOrderLines: PropTypes.bool,
      quantity: PropTypes.number,
      displaySize: PropTypes.string,
      giftCardDetail: PropTypes.shape({
        giftCards: PropTypes.arrayOf(
          PropTypes.shape({
            giftCardNumber: PropTypes.string,
          })
        ),
      }),
      linePriceInformation: PropTypes.shape({
        unitPrice: PropTypes.number,
      }),
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        })
      ),
      lineHolds: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string,
          lastHoldDate: PropTypes.string,
        })
      ),
      nestedVasOrderLines: PropTypes.arrayOf(
        PropTypes.shape({
          item: PropTypes.shape({
            itemDescription: PropTypes.string,
          }),
          styleNumber: PropTypes.string,
          colorCode: PropTypes.string,
          linePriceInformation: PropTypes.shape({
            unitPrice: PropTypes.string,
          }),
        })
      ),
    })
  ),
  ExpandedContent: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  collapsePanel: {
    width: '100%',
  },
  itemDetailCellRoot: {
    userSelect: 'text',
    cursor: 'text',
    padding: '5px 0 5px 15px',
    margin: 'none',
  },
  headerRow: {
    height: '30px',
  },
  icon: {
    'fontSize': '18px',
    'marginLeft': '5px',
    '&.warning': {
      color: theme.palette.error.main,
    },
    '&.hold': {
      color: theme.palette.grey[500],
    },
  },
  holdText: {
    color: theme.palette.error.main,
    display: 'inline',
    marginInlineStart: '2px',
  },
  expandButton: {
    minWidth: '7.6em',
    color: theme.palette.primary.main,
  },
  giftCardLink: {
    color: theme.palette.primary.main,
  },
  giftCardLinkList: {
    padding: 0,
  },
  itemRow: {
    padding: `0 0 ${theme.spacing(0.5)}px 0`,
    border: 'none',
  },
  itemCellWithBorder: {
    display: 'table-cell',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  itemCellWithNoBorder: {
    display: 'table-cell',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
    border: 'none',
  },
  lineStatus: {
    fontSize: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tooltip: {
    color: theme.palette.common.white,
    fontSize: '1rem',
  },
  alignTop: {
    verticalAlign: 'top',
  },
}));

export default ItemDetails;
