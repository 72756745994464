const translations = {
  ACTIVATE_PAYMENT: {
    description: 'label for button that activates a payment on an order',
    primaryValue: 'Activate',
    stringKey: 'activatePayment',
  },
  ACTIVATE_PAYMENT_ERROR: {
    description: 'error message for activating payment method failure',
    primaryValue: 'Failed to activate payment method',
    stringKey: 'activatePaymentError',
  },
  ACTIVATE_PAYMENT_METHOD: {
    description: 'title for confirmation modal to activate payment method',
    primaryValue: 'Activate Payment Method',
    stringKey: 'activatePaymentMethod',
  },
  ACTIVATE_PAYMENT_SUCCESS: {
    description: 'success message when payment method is successfully activated',
    primaryValue: 'Payment method successfully activated',
    stringKey: 'activatePaymentSuccess',
  },
  BILLING_ADDRESS_1: {
    description: 'Label for text input for 1st line of an address',
    primaryValue: 'Billing Address Line 1',
    stringKey: 'billingAddressLine1',
  },
  BILLING_ADDRESS_2: {
    description: 'Label for text input for 2nd line of an address',
    primaryValue: 'Billing Address Line 2',
    stringKey: 'billingAddressLine2',
  },
  BILLING_CITY: {
    description: 'label for text input for city (specifically city for billing purposes)',
    primaryValue: 'Billing City',
    stringKey: 'billingCity',
  },
  CONFIRM_ACTIVATING_CARD: {
    description:
      'dialog text for confirmation on activating a payment method; the action will suspend the current active payment method.',
    primaryValue:
      'Activating this card will automatically suspend the current active payment method, ending in',
    stringKey: 'confirmActivatePaymentMethod',
  },
  CONFIRM_ACTIVATING_METHOD: {
    description:
      'dialog text for confirmation on activating a payment method when it is currently suspended.',
    primaryValue: 'Please confirm that you want to activate the selected payment method, ending in',
    stringKey: 'confirmActivateNewPaymentMethod',
  },
  CONFIRM_SUSPENDING_METHOD: {
    description:
      'dialog text for confirmation on suspending a payment method when it is currently active.',
    primaryValue:
      'Please confirm that you want to suspend the current active payment method, ending in',
    stringKey: 'confirmSuspendNewPaymentMethod',
  },
  FIRST_NAME: {
    description: 'label on the Address form for first name',
    primaryValue: 'First Name',
    stringKey: 'firstName',
  },
  LAST_NAME: {
    description: 'label on the Address form for last name',
    primaryValue: 'Last Name',
    stringKey: 'lastName',
  },
  SURE_TO_CONTINUE: {
    description: 'text asking user if they are sure they want to continue with the current action',
    primaryValue: 'Are you sure you want to continue?',
    stringKey: 'sureToContinue',
  },
  SUSPEND_PAYMENT: {
    description: 'label on the Payment Details page for "suspendPayment"',
    primaryValue: 'Suspend',
    stringKey: 'suspendPayment',
  },
  SUSPEND_PAYMENT_ERROR: {
    description: 'error message when payment method fails to be suspended',
    primaryValue: 'Failed to suspend payment method',
    stringKey: 'suspendPaymentFailure',
  },
  SUSPEND_PAYMENT_METHOD: {
    description: 'title for confirmation modal to suspend payment method',
    primaryValue: 'Suspend Payment Method',
    stringKey: 'suspendPaymentMethod',
  },
  SUSPEND_PAYMENT_SUCCESS: {
    description: 'success message when payment method is successfully suspended',
    primaryValue: 'Payment method successfully suspended',
    stringKey: 'suspendPaymentSuccess',
  },
  YES: {
    description:
      'text to confirm that user would like to proceed or answer a question in the affirmative',
    primaryValue: 'Yes',
    stringKey: 'yes',
  },
  IBAN: {
    description: 'label for IBAN for bank payment method in order payment details',
    primaryValue: 'IBAN',
    stringKey: 'iban',
  },
  SWIFT_BIC: {
    description: 'label of a text field for the swift or bic of a bank account',
    primaryValue: 'Swift/BIC',
    stringKey: 'swiftBic',
  },
  BILLING_INFORMATION: {
    description: 'section label for a billing address',
    primaryValue: 'Billing Information',
    stringKey: 'billingInformation',
  },
  COUNTRY: {
    description: 'label on the Address form for country',
    primaryValue: 'Country',
    stringKey: 'country',
  },
  ADD_BANK_ERROR: {
    description: 'error thrown when adding a bank payment method to an order fails',
    primaryValue: 'Error adding bank to order: ',
    stringKey: 'addBankError',
  },
  ADD_BANK_SUCCESS: {
    description: 'success message when a bank has been successfully added to an order',
    primaryValue: 'Bank payment method has been added to the order.',
    stringKey: 'addBankSuccess',
  },
  IBAN_VALIDATION_ERROR: {
    description: 'error thrown by text field when IBAN has failed validation',
    primaryValue: 'IBAN is not Valid',
    stringKey: 'ibanValidationError',
  },
  IBAN_NOT_VALID: {
    description: 'error message to display on iban input when not valid',
    primaryValue: 'IBAN not valid',
    stringKey: 'ibanNotValid',
  },
  SWIFT_BIC_VALIDATION_ERROR: {
    description: 'error thrown by text field when bank Swift/BIC code has failed validation',
    primaryValue: 'Swift/BIC code is not Valid',
    stringKey: 'swiftBicValidationError',
  },
  SWIFT_NOT_VALID: {
    description: 'error message to display on swift input when it is not valid.',
    primaryValue: 'Swift/BIC not valid',
    stringKey: 'swiftNotValid',
  },
};

export default translations;
