import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import EXCHANGE_PREVIEW_QUERY from '../../../../queries/exchangePreview.query';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../hooks/useSnacks';
import OrderContext from '../../../../store/contexts/orderContext';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import dialogActions from '../../../../store/actions/dialogActions';
import translations from './exchange.i18n';
import ThreeColumnContainer from './threeColumnContainer';
import ItemDetails from '../shared/itemDetails';
import StyleLink from '../../content/shared/itemLink';
import Discount from './discountSection';
import ExchangePriceSummary from './priceSummary';
import SelectedReplacement from './selectedReplacement';
import {
  ReasonCodeTypes,
  ReasonCodeAppId,
  ApiTimeOut,
  DiscountTypes,
} from '../../../../constants/dialog.const';
import { appropriateLanguage } from '../../../../utils/language';
import { getBillingEmail } from '../../../../utils/order';
import { getNewSkuPrice } from '../../../../utils/price';

export default function Step3() {
  const classes = useStyles();
  const [randomId] = useState(profileId || uuidv4());
  const [i18State] = useContext(I18nContext);
  const { setError } = useSnacks();

  const [consumerState] = useContext(ConsumerContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const {
    selectedLines, // Items chosen from the return order
    selectedSkus, // the replacements being chosen
    exchangeCreditValue,
    exchangeCountry,
    exchangeLocale,
  } = dialogState;
  const { setExchangeTotalPrice, setSelectedSkuInStock } = dialogActions;

  const { orderNumber: returnOrderNumber, currency, customerProfileReference } = orderDetail;
  const { profileId, consumerType } = consumerState;

  const {
    VIEW_CART,
    ORIGINAL,
    NEW,
    DISCOUNT,
    EXCHANGE_PRICING_SUMMARY,
    EXCHANGE_SUMMARY_LOADING,
    STYLE,
    EXCHANGE_PREVIEW_ERROR,
  } = useMemoTranslations(translations);

  const flatSkus = useMemo(() => Object.values(selectedSkus), [selectedSkus]);

  const { data, loading, error } = useQuery(REASON_CODES_QUERY, {
    variables: {
      appId: ReasonCodeAppId,
      omsRegionReference: orderDetail?.omsRegionReference,
      type: ReasonCodeTypes.PRICE_ADJUSTMENT,
      language: appropriateLanguage(i18State.language),
    },
    skip: !orderDetail?.orderNumber || selectedLines.length === 0,
    notifyOnNetworkStatusChange: true,
  });

  const [getExchangePreview, { data: exchangePreviewData, loading: previewLoading }] = useLazyQuery(
    EXCHANGE_PREVIEW_QUERY,
    {
      onError: (error) => {
        setError(`${EXCHANGE_PREVIEW_ERROR}:  ${error}`);
      },
      onCompleted: () => {
        if (exchangePreviewData.exchangePreview?.error) {
          const errorMessageOnJobCompletion = `${EXCHANGE_PREVIEW_ERROR}: ${exchangePreviewData.exchangePreview.error.message}`;
          setError(errorMessageOnJobCompletion);
          return;
        }
        const inStockIds = [];
        exchangePreviewData.exchangePreview?.response?.fulfillmentGroups?.map((grp) =>
          grp?.items?.map((previewItem) => inStockIds.push(previewItem?.id))
        );

        Object.entries(selectedSkus).forEach(([lineNumber, sku]) => {
          const { omoboGeneratedId, inStock } = sku;
          const newInStock = inStockIds.includes(omoboGeneratedId);
          /*
            If our inStock value doesn't match what the service is telling us, update
            our selectedSkus state to reflect the exchangePreview service response.
          */
          if (inStock !== newInStock) {
            dialogDispatch(setSelectedSkuInStock(lineNumber, newInStock));
          }
        });

        const {
          priceSummary: { total, taxTotal, discount },
        } = exchangePreviewData?.exchangePreview?.response;

        const newExchangeTotal = total - discount + taxTotal;

        // Set Exchange Total Price in dialogState
        dialogDispatch(setExchangeTotalPrice(newExchangeTotal));
      },
    }
  );

  const prevFlatSkus = useRef();

  useEffect(() => {
    const invalidDiscountsExist = flatSkus.some(
      (sku) =>
        // value without a reason
        (sku.discount.value && !sku.discount.reason.id) ||
        // amount off or exact price exceeds item price
        ([DiscountTypes.AMOUNT_OFF, DiscountTypes.EXACT_PRICE].includes(sku.discount.type) &&
          sku.discount.value > sku.priceInfo.total) ||
        // percent off exceeds 100 percent
        (sku.discount.type === DiscountTypes.PERCENT_OFF && Number(sku.discount.value) > 100)
    );

    const discountValueChanges = prevFlatSkus.current
      ? flatSkus.filter(
          (sku, i) =>
            sku.discount.value !== prevFlatSkus?.current?.[i].discount.value ||
            sku.discount.reason.id !== prevFlatSkus?.current?.[i].discount.reason.id ||
            sku.discount.type !== prevFlatSkus?.current?.[i].discount.type
        )
      : [];

    /* 
      Only re-query if there are no invalid discounts applied (see logic above), and either:
        - we haven't queried yet (prevFlatSkus doesn't exist), OR
        - the values in the discount form have changed
    */
    if (
      (!invalidDiscountsExist && !prevFlatSkus.current) ||
      (!invalidDiscountsExist && prevFlatSkus.current && discountValueChanges.length)
    ) {
      const userInfo = {
        identityType: consumerType,
      };

      if (!customerProfileReference) {
        userInfo.email = getBillingEmail(orderDetail);
        userInfo.upmId = randomId;
      } else {
        userInfo.upmId = profileId;
      }
      getExchangePreview({
        variables: {
          input: {
            parentReturnOrderNumber: returnOrderNumber,
            email: getBillingEmail(orderDetail),
            country: exchangeCountry,
            currency,
            locale: exchangeLocale,
            items: flatSkus.map(({ selectedSize, discount, priceInfo, omoboGeneratedId }) => {
              const amountPerUnit = priceInfo?.total - getNewSkuPrice(priceInfo?.total, discount);
              const roundedDiscount = Math.round(amountPerUnit * 100) / 100;
              return {
                id: omoboGeneratedId,
                skuId: selectedSize.skuId,
                quantity: 1,
                priceAdjustments: {
                  discount: {
                    id: uuidv4(),
                    amountPerUnit: roundedDiscount,
                    type: 'PRICE_OVERRIDE',
                    reasonCode: discount?.reason?.id || '',
                  },
                },
              };
            }),
          },
          timeout: ApiTimeOut,
          userInfo,
        },
      });
    }
    // Update prevFlatSkus to be evaluated the next time flatSkus changes.
    prevFlatSkus.current = flatSkus;
  }, [flatSkus]);

  const generateItemRows = () =>
    Object.entries(selectedLines).map(([lineNumber, line]) => {
      return (
        <div className={classes.step} key={`row-${lineNumber}`}>
          {/* Original Item Description */}
          <div className={classes.itemHeader}>
            <Typography display={'inline'} className={classes.columnHeaders}>
              {line.item.itemDescription}&nbsp;
            </Typography>
            <span className={classes.typographySpacer}></span>
            <Typography display={'inline'} className={classes.greyLabel} style={{ height: '100%' }}>
              {STYLE}:&nbsp;
              <StyleLink orderDetail={orderDetail} line={line} />
            </Typography>
          </div>
          {/* Display item being exchanged, new item chosen, and discount section */}
          <ThreeColumnContainer
            headers={[ORIGINAL, NEW, DISCOUNT]}
            firstColumn={<ItemDetails testid={`original-item-${lineNumber}`} item={line} />}
            secondColumn={
              <SelectedReplacement orderLineNumber={lineNumber} displayProductDetailsLink={true} />
            }
            thirdColumn={
              <Discount
                disabled={selectedSkus[lineNumber]?.inStock === false}
                lineNumber={lineNumber}
                reasonCodes={{ data: data?.reasonCodesV2?.reasons, loading, error }}
              />
            }
          />
        </div>
      );
    });

  return (
    <Box>
      <Divider variant='middle' />
      {/* Header */}
      <Typography
        variant={'h5'}
        component={'h2'}
        style={{ margin: '.5rem 1rem' }}
        className={classes.greyLabel}>
        {VIEW_CART} (<span className={classes.innerLabel}>{flatSkus.length}</span>)
      </Typography>

      {/* Items to display */}
      <>{generateItemRows()}</>

      {/* Pricing Summary */}
      {Array.isArray(Object.values(selectedSkus)) && (
        <>
          <ThreeColumnContainer
            headers={['', '', EXCHANGE_PRICING_SUMMARY]}
            thirdColumn={
              previewLoading ? (
                <CircularProgress
                  aria-label={EXCHANGE_SUMMARY_LOADING}
                  data-testid={'exchange-summary-loading'}
                />
              ) : (
                <ExchangePriceSummary
                  currency={orderDetail?.currency}
                  exchangePriceSummaryData={
                    exchangePreviewData?.exchangePreview?.response?.priceSummary
                  }
                  exchangeCreditValue={exchangeCreditValue}
                />
              )
            }
          />
          <Divider variant='middle' />
        </>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  itemHeader: {
    marginBottom: '1rem',
  },
  innerLabel: {
    color: theme.palette.common.black,
  },
  itemSeparator: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  typographySpacer: {
    marginLeft: '0.5rem',
  },
  step: {
    margin: '1rem 1rem 1rem 1rem',
    paddingBottom: '1rem',
  },
  greyLabel: {
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  columnHeaders: {
    fontWeight: 'bold',
  },
}));
