/* React/Utils */
import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

/* Local */
import PidSearchResultsRow from './pidSearchResultsRow';
import translations from './pidReview.i18n';
import { stableSort, sortComparator } from '../../utils/reactUtils';
import { mapTextToPid } from '../../utils/pidReview';
import PERSONALIZATIONS_QUERY from '../../queries/personalizations.query';
import DISPOSITION_MUTATION from '../../mutations/disposition.mutation';
import PID_STATUS_MUTATION from '../../mutations/pidStatus.mutation';
import useSnacks from '../../hooks/useSnacks';
import useMemoTranslations from '../../hooks/useMemoTranslations';
import useGetPids from '../../hooks/useGetPids';

import { PidReviewSearchContext } from '../../store/contexts/pidReviewSearchContext';
import searchActions from './../../store/actions/pidReviewSearchActions';

/**
 * This component is used to display PiD search results
 *
 * @param {object} props – React props object
 */
const PidSearchTable = ({ searchResults, status }) => {
  const [perPage] = useState(50);
  const [searchState, searchDispatch] = useContext(PidReviewSearchContext);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('referenceData.orderDate');
  const [selected, setSelected] = useState(new Set());
  const [rowsInView, setRowsInView] = useState(() =>
    searchResults
      .slice(Math.abs(page - 1) * perPage, Math.abs(page - 1) * perPage + perPage)
      .map((r) => r?.id)
  );
  const selectedPidIds = React.useMemo(() => Array.from(selected), [selected]);

  const { setSearchTablePage } = searchActions;

  const { page } = searchState;
  const [decoratedSearchResults, setDecoratedSearchResults] = useState(searchResults);

  const classes = useStyles();
  const { setError, setSnack, setLoading, setStopLoading } = useSnacks();

  const {
    APPROVE,
    BILLING_NAME,
    FOLLOW_UP,
    HELD,
    METRIC_ID,
    NO_SEARCH_RESULT,
    ORDER_NUMBER,
    ORDER_DATE,
    PID_LOCATION,
    PID_TEXT,
    QUEUE,
    REGION,
    REJECT,
    SEARCH_COUNTRY,
    SELECT_ALL,
    SHIPPING_NAME,
    TIME_IN_QUEUE,
    PERSONALIZATIONS_ERROR,
    PID_DISPOSITION_ERROR,
    PID_DISPOSITION_SUCCESS,
    PID_STATUS_CHANGE_ERROR,
    PID_STATUS_CHANGE_SUCCESS,
  } = useMemoTranslations(translations);

  const [
    queryPersonalizations,
    { data: personalizationsData, error: personalizationsError },
  ] = useLazyQuery(PERSONALIZATIONS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${PERSONALIZATIONS_ERROR} ${personalizationsError.message}`);
    },
    onCompleted: () => {
      const newSearchResults = mapTextToPid(searchResults, personalizationsData.personalizations);
      setDecoratedSearchResults(newSearchResults);
      setStopLoading();
    },
  });

  const [putPidStatus] = useMutation(PID_STATUS_MUTATION, {
    onError: (error) => {
      setError(`${PID_STATUS_CHANGE_ERROR} ${error.message}`);
    },
    onCompleted: () => {
      setSnack(PID_STATUS_CHANGE_SUCCESS, null, true);
      runQueryPidSearch();
      setSelected(new Set());
    },
  });

  // Call to pid review search api
  const { runQueryPidSearch } = useGetPids();

  // Call to process PiD disposition
  const [putPidDisposition] = useMutation(DISPOSITION_MUTATION, {
    onError: (error) => {
      setError(`${PID_DISPOSITION_ERROR} ${error}`);
    },
    onCompleted: () => {
      runQueryPidSearch();
      setSnack(PID_DISPOSITION_SUCCESS, null, true);
      setSelected(new Set());
    },
  });

  useEffect(() => {
    setDecoratedSearchResults(searchResults);
    if (searchResults.length) {
      setLoading();

      const commaSeparatedPidIds = rowsInView.join(',');
      queryPersonalizations({
        variables: {
          pidIds: commaSeparatedPidIds,
        },
      });
    } else {
      setStopLoading();
    }
  }, [rowsInView]);

  useEffect(() => {
    const viewableRows = sortReviews(searchResults)
      .slice(Math.abs(page - 1) * perPage, Math.abs(page - 1) * perPage + perPage)
      .map((r) => r?.id);
    setRowsInView(viewableRows);
  }, [searchResults, page, order, orderBy]);

  const handlePageChange = (event, newPage) => {
    searchDispatch(setSearchTablePage(newPage));
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortReviews = (reviews) => stableSort(reviews, sortComparator, order, orderBy);

  const toggleSelectAll = useCallback((event) => {
    /*
      If the 'select all' box is being checked, we add all rows on
      the current page to the array of 'selected' items. Otherwise,
      we clear the array of 'selected' items.
    */
    if (event.target.checked) {
      setSelected(new Set([...rowsInView]));
      return;
    }
    setSelected(new Set());
  });

  const displayedReviews = useMemo(
    () =>
      sortReviews(decoratedSearchResults).slice(
        Math.abs(page - 1) * perPage,
        Math.abs(page - 1) * perPage + perPage
      ),
    [decoratedSearchResults, order, orderBy, page]
  );

  const onClickDisposition = React.useCallback(
    (disposition) => {
      setLoading();
      putPidDisposition({
        variables: {
          pidReviewIds: selectedPidIds,
          disposition,
        },
      });
    },
    [putPidDisposition, selected]
  );

  const onStatusClick = (status) => {
    setLoading();
    // convert set to array
    putPidStatus({
      variables: { pidIds: [...selected], status },
    });
  };

  return (
    <>
      <Box style={{ position: 'relative' }}>
        <Box data-testid={'pid-results-table-container'}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell
                  align='left'
                  style={{
                    paddingLeft: '4px',
                  }}>
                  <Checkbox
                    checked={selected.size > 0}
                    onChange={toggleSelectAll}
                    inputProps={{
                      'aria-label': SELECT_ALL,
                    }}
                    color='primary'
                  />
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  {PID_LOCATION}
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  {PID_TEXT}
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'timeInQueue'}
                    direction={orderBy === 'timeInQueue' ? order : 'asc'}
                    onClick={createSortHandler('timeInQueue')}>
                    {TIME_IN_QUEUE}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'customizationId.value'}
                    direction={orderBy === 'customizationId.value' ? order : 'asc'}
                    onClick={createSortHandler('customizationId.value')}>
                    {METRIC_ID}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'referenceData.billingName'}
                    direction={orderBy === 'referenceData.billingName' ? order : 'asc'}
                    onClick={createSortHandler('referenceData.billingName')}>
                    {BILLING_NAME}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'referenceData.shippingName'}
                    direction={orderBy === 'referenceData.shippingName' ? order : 'asc'}
                    onClick={createSortHandler('referenceData.shippingName')}>
                    {SHIPPING_NAME}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'referenceData.orderNo'}
                    direction={orderBy === 'referenceData.orderNo' ? order : 'asc'}
                    onClick={createSortHandler('referenceData.orderNo')}>
                    {ORDER_NUMBER}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'referenceData.orderDate'}
                    direction={orderBy === 'referenceData.orderDate' ? order : 'asc'}
                    onClick={createSortHandler('referenceData.orderDate')}>
                    {ORDER_DATE}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'businessUnit'}
                    direction={orderBy === 'businessUnit' ? order : 'asc'}
                    onClick={createSortHandler('businessUnit')}>
                    {REGION}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHeader} align='left'>
                  <TableSortLabel
                    active={orderBy === 'country'}
                    direction={orderBy === 'country' ? order : 'asc'}
                    onClick={createSortHandler('country')}>
                    {SEARCH_COUNTRY}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid={'pid-results-table-body'}>
              {displayedReviews.map((pidReview, i) => {
                return (
                  <PidSearchResultsRow
                    data-testid={`pid-results-row-${i}`}
                    key={pidReview?.id}
                    review={pidReview}
                    setSelected={setSelected}
                    selected={selected.has(pidReview?.id)}
                    hover
                    tabIndex={-1}
                  />
                );
              })}
            </TableBody>
          </Table>
          {!searchResults.length && (
            <Typography
              variant='subtitle1'
              id='noSearchResults'
              align='center'
              className={classes.zeroSearchResults}
              component='div'>
              {NO_SEARCH_RESULT}
            </Typography>
          )}
        </Box>
      </Box>
      <Box id='pid-table-footer' className={classes.tableFooter}>
        <Pagination
          page={page}
          count={Math.ceil(searchResults.length / perPage)}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
          renderItem={(item) => <PaginationItem {...item} />}
        />
        <Box className={classes.ctaButtons}>
          <Button
            color='primary'
            variant='contained'
            data-testid='pid-review-change-status-queue'
            className={classes.actionButton}
            onClick={() => onStatusClick('Reviewable')}
            disabled={selected.size === 0 || status === 'reviewable'}>
            {QUEUE}
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.actionButton}
            data-testid='pid-review-change-status-follow-up'
            onClick={() => onStatusClick('PendingFeedback')}
            disabled={selected.size === 0 || status === 'pendingFeedback'}>
            {FOLLOW_UP}
          </Button>
          <Button
            color='primary'
            variant='contained'
            data-testid='pid-review-change-status-hold'
            className={classes.actionButton}
            onClick={() => onStatusClick('Hold')}
            disabled={selected.size === 0 || status === 'held'}>
            {HELD}
          </Button>
        </Box>
        <Box className={classes.ctaButtons}>
          <Button
            data-testid='approve-btn'
            disabled={selected.size === 0}
            color='primary'
            variant='contained'
            className={classes.actionButton}
            onClick={() => onClickDisposition('Accepted')}>
            {APPROVE}
          </Button>
          <Button
            data-testid='reject-btn'
            disabled={selected.size === 0}
            color='primary'
            variant='contained'
            className={classes.actionButton}
            onClick={() => onClickDisposition('Rejected')}>
            {REJECT}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PidSearchTable;

PidSearchTable.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      referenceData: PropTypes.shape({
        shippingName: PropTypes.string,
        reviewId: PropTypes.string,
        orderNo: PropTypes.string,
        orderLineRef: PropTypes.string,
        orderDate: PropTypes.string,
        billingName: PropTypes.string,
      }),
      pidTextInfo: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          text: PropTypes.string,
        })
      ),
      locale: PropTypes.string,
      customizationId: PropTypes.shape({
        value: PropTypes.string,
        type: PropTypes.string,
      }),
      id: PropTypes.string,
      creationDate: PropTypes.string,
      country: PropTypes.string,
      businessUnit: PropTypes.string,
    })
  ),
  status: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '900px',
    overflowX: 'scroll',
    position: 'relative',
    borderCollapse: 'separate',
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    insetBlockStart: 0,
    backgroundColor: theme.palette.common.white,
    maxWidth: '100%',
    zIndex: 1,
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  tableFooter: {
    'position': 'sticky',
    'bottom': '0',
    'left': '0',
    'right': '0',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'paddingTop': '1rem',
    'flexWrap': 'wrap',
    'background': theme.palette.common.white,
    '& > *': {
      padding: '.25rem 0',
    },
  },
  zeroSearchResults: {
    padding: theme.spacing(3.75),
  },
  navLink: {
    fontWeight: 'bold',
  },
  prevLink: {
    paddingRight: theme.spacing(1.5),
  },
  nextLink: {
    paddingLeft: theme.spacing(1.5),
  },
  topLinks: {
    marginTop: theme.spacing(1),
  },
  tableCap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  ctaButtons: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  actionButton: {
    marginRight: '0.5rem',
    whiteSpace: 'nowrap',
  },
}));
