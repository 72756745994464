/** react / utils */
import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import { withRouter } from 'react-router-dom';
import mapValues from 'lodash/mapValues';

/** material ui */
import Box from '@material-ui/core/Box';

/** local */
import { DialogContext } from '../../../store/contexts/dialogContext';
import Comments from './comments';
import Details from './details';
import Charges from './charges';
import NavDrawer from './navDrawer';
import Emails from './emails';
import CloudPayments from './payments/cloud';
import CspPayments from './payments/csp';
import Efapiao from './efapiao';
import translations from './content.i18n.js';
import TabPanel from '../../shared/tabPanel';
import { useHistoryPushWithSessionId } from '../../../hooks/useHistorySessionId';
import useHasPermission from '../../../hooks/useHasPermission';
import { ViewCloudPaymentTab, ViewCspPaymentTab } from '../../../constants/permissions.const';
import Geo from '../../../constants/geos.const';

/**
 * Container for navigable content, houses NavDrawer and SwipeableViews
 */
function Content({ match, region, orderType }) {
  const setRoute = useHistoryPushWithSessionId();
  const [dialogState] = useContext(DialogContext);
  const route = match && match.params && match.params.route;
  const orderId = match && match.params && match.params.orderId;
  const { i18nString } = useContext(NikeI18nContext);
  const { hasPermission } = useHasPermission();
  const { NIKEGS, NIKEXA, NIKEHK, CHINA } = Geo;

  const routes = [{ name: 'details', component: <Details /> }];

  // add the charges tabs if order is not from NIKEGS or NIKEXA
  if (![NIKEGS, NIKEXA].includes(region)) {
    routes.push({ name: 'charges', component: <Charges /> });
    // add the payments tabs if order is not from NIKEGS, NIKEXA or NIKEHK
    if (region !== NIKEHK) {
      // Hides payment tab unless you have correct permission, and pushes it
      hasPermission(ViewCloudPaymentTab) &&
        routes.push({ name: 'paymentDetails', component: <CloudPayments /> });
    }
  }
  // add the efapiao tab if order is from China
  // TODO remove dev permission when efapiao tab is getting data
  if (region === 'NIKECN') {
    if (orderType !== 'RETURN_ORDER') {
      routes.push({
        name: 'efapiao',
        component: <Efapiao paramOrderId={orderId} />,
      });
    }
  } else {
    // if we're not in china add the emails tab
    routes.push({ name: 'emails', component: <Emails paramOrderId={orderId} /> });
  }
  routes.push({ name: 'comments', component: <Comments paramOrderId={orderId} /> });

  if (![NIKEGS, NIKEXA, NIKEHK, CHINA].includes(region)) {
    hasPermission(ViewCspPaymentTab) &&
      routes.push({ name: 'cspPayments', component: <CspPayments /> });
  }

  const routeIndex = routes.findIndex((routeObj) => routeObj.name === route);

  // tabs are zero indexed, default to details
  const index = routeIndex === -1 ? 0 : routeIndex;

  const handleChange = (event, newIndex) => {
    setRoute(`/order/${orderId}/${routes[newIndex].name}`);
  };

  const { ARIA_TAB_NAV } = mapValues(translations, i18nString);

  return (
    <Box mx={2} display='flex' height='100%'>
      <NavDrawer
        routes={routes.map((route) => route.name)}
        currIndex={index}
        onChange={handleChange}
      />
      <Box role={dialogState.isOpen ? 'none' : 'main'} width='95%' aria-label={ARIA_TAB_NAV}>
        {routes.map((route, i) => (
          <TabPanel
            key={route.name}
            navIndex={i}
            route={route.name}
            currIndex={index}
            hidden={index !== i}>
            {route.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default withRouter(Content);
