import React, { useContext } from 'react';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import translations from './invoiceDetails.i18n';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import BasicTableRow from '../../../../shared/table/tableRow';
import InvoiceDetailsSection from './invoiceDetails.section';
import ResendEmail from './resendEmail';
import { FormattedCurrency } from '../../../../shared/formatCurrency';
import { formatDate } from '../../../../../utils/date';
import Geo from '../../../../../constants/geos.const';
import CopyButton from '../../../infoDrawer/copyButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { trimTo } from '../../../../../utils/name';
import HasPermission from '../../../../shared/hasPermission';
import { ResendInvoice } from '../../../../../constants/permissions.const';
import {
  getHeaderCharges,
  getHeaderDiscounts,
  getHeaderTaxes,
  getLineSubtotals,
  getLineCharges,
  getAllLineCharges,
  getAllLineDiscounts,
  getLineTaxes,
  getAllLineTaxes,
} from '../../../../../utils/payment';

/**
 * Invoice details component.
 * Provide it:
 * invoice details - the invoiceDetails object used in this component
 * locale - to format local date correctly
 */
export default function InvoiceDetails({ invoiceDetails, locale, transactionKey }) {
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();
  const {
    TABLE_HEADER_STYLE_NUMBER,
    TABLE_HEADER_STYLE_NAME,
    TABLE_HEADER_CLR,
    TABLE_HEADER_SIZE,
    TABLE_HEADER_QTY,
    TABLE_HEADER_UNIT,
    TABLE_HEADER_EXT_PRICE,
    TABLE_HEADER_EXT_CHRG,
    TABLE_HEADER_TAX,
    TABLE_HEADER_TOTAL,
    INVOICE_DETAILS_HEADER,
    INVOICE_DETAILS_INVOICE_NUMBER_LABEL,
    INVOICE_DETAILS_INVOICE_DATE_LABEL,
    INVOICE_DETAILS_INVOICE_TYPE_LABEL,
    INVOICE_DETAILS_ORDER_NUMBER,
    INVOICE_DETAILS_ORDER_DATE,

    INVOICE_DETAILS_SUMMARY_HEADER,
    INVOICE_DETAILS_SUMMARY_TYPE,
    INVOICE_DETAILS_SUMMARY_CARD,
    INVOICE_DETAILS_SUMMARY_AMOUNT,
    INVOICE_DETAILS_SUMMARY_CARD_NUMBER,
    INVOICE_DETAILS_SUMMARY_EXPIRATION,

    INVOICE_DETAILS_BILLING_ADDRESS_HEADER,
    INVOICE_DETAILS_BILLING_ADDRESS_NAME,
    INVOICE_DETAILS_BILLING_ADDRESS_ADDRESS1,
    INVOICE_DETAILS_BILLING_ADDRESS_ADDRESS2,
    INVOICE_DETAILS_BILLING_ADDRESS_DAYTIME,

    INVOICE_DETAILS_CHARGES_DISCOUNT_HEADER,
    INVOICE_DETAILS_CHARGES_DISCOUNT_CHARGES,
    INVOICE_DETAILS_CHARGES_DISCOUNT_DISCOUNTS,
    INVOICE_DETAILS_CHARGES_DISCOUNT_TAX,

    INVOICE_DETAILS_TOTALS_HEADER,
    INVOICE_DETAILS_TOTALS_LINE_SUBTOTALS,
    INVOICE_DETAILS_TOTALS_TOTAL_CHARGES,
    INVOICE_DETAILS_TOTALS_TOTAL_DISCOUNTS,
    INVOICE_DETAILS_TOTALS_TOTAL_TAX,

    INVOICE_DETAILS_TRANSACTIONS_HEADER,
    INVOICE_DETAILS_TRANSACTIONS_TRANSACTION_DATE,
    INVOICE_DETAILS_TRANSACTIONS_TRANSACTION_TYPE,
    INVOICE_DETAILS_TRANSACTIONS_AMT,
    INVOICE_DETAILS_TRANSACTIONS_STATUS,
    INVOICE_DETAILS_TRANSACTIONS_REF_ID,
  } = mapValues(translations, i18nString);

  const { orderLines, headerCharges, headerTaxes } = invoiceDetails;

  const invoiceData = invoiceDetails.invoiceCollections.find(
    (invoice) => invoice?.chargeTransactionDetails?.chargeTransactionKey === transactionKey
  );

  function formatCurrency(value, currency) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }

  const allowedResendInvoiceСountries = [Geo.US];

  const isShowResendInvoiceButton = allowedResendInvoiceСountries.includes(
    invoiceDetails.omsRegionReference.toUpperCase()
  );

  const getPaymentPreview = () => {
    const displayCreditCardNumberPrefix = '**** **** **** ';
    const displayCreditCardNumber =
      invoiceData?.chargeTransactionDetails?.paymentMethod?.displayCreditCardNumber;
    if (displayCreditCardNumber) return displayCreditCardNumberPrefix + displayCreditCardNumber;
    else
      return (
        invoiceData?.chargeTransactionDetails?.paymentMethod?.displayDebitCardNumber ||
        invoiceData?.chargeTransactionDetails?.paymentMethod?.displayGiftCardNumber
      );
  };

  const requestId = invoiceData?.chargeTransactionDetails?.paymentMethod.paymentReference4;

  return invoiceDetails ? (
    <>
      <div className={classes.invoiceWrapperCol}>
        {/* invoice details */}
        <div className={classes.invoiceDetailsWrapper}>
          <InvoiceDetailsSection
            header={INVOICE_DETAILS_HEADER}
            rows={[
              {
                label: INVOICE_DETAILS_INVOICE_NUMBER_LABEL,
                value: invoiceDetails.resourceId,
                testId: 'invoice-number',
              },
              {
                label: INVOICE_DETAILS_INVOICE_DATE_LABEL,
                value: formatDate(invoiceDetails.date, locale),
                testId: 'invoice-date',
              },
              {
                label: INVOICE_DETAILS_INVOICE_TYPE_LABEL,
                value: invoiceDetails.invoiceType,
                testId: 'invoice-type',
              },
              {
                label: INVOICE_DETAILS_ORDER_NUMBER,
                value: invoiceDetails.orderNumber,
                testId: 'invoice-order-number',
              },
              {
                label: INVOICE_DETAILS_ORDER_DATE,
                value: formatDate(invoiceDetails.orderCreateDate, locale),
                testId: 'invoice-order-date',
              },
            ]}
          />
        </div>
        {/* summary */}
        <div className={classes.invoiceSummary}>
          <InvoiceDetailsSection
            header={INVOICE_DETAILS_SUMMARY_HEADER}
            rows={[
              {
                label: INVOICE_DETAILS_SUMMARY_TYPE,
                value:
                  invoiceData.chargeTransactionDetails?.paymentMethod.creditCardType ||
                  invoiceData.chargeTransactionDetails?.paymentMethod?.paymentType,
                testId: 'invoice-summary-type',
              },
              {
                label: INVOICE_DETAILS_SUMMARY_CARD,
                value: invoiceData?.chargeTransactionDetails?.paymentMethod?.paymentType,
                testId: 'invoice-summary-card',
              },
              {
                label: INVOICE_DETAILS_SUMMARY_AMOUNT,
                value: formatCurrency(invoiceData?.amountCollected, invoiceDetails.currency),
                testId: 'invoice-summary-amount',
              },
              {
                label: INVOICE_DETAILS_SUMMARY_CARD_NUMBER,
                value: getPaymentPreview(),
                testId: 'invoice-summary-card-number',
              },
              {
                label: INVOICE_DETAILS_SUMMARY_EXPIRATION,
                value: invoiceData?.chargeTransactionDetails?.paymentMethod?.creditCardExpiration,
                testId: 'invoice-summary-expiration',
              },
            ]}
          />
        </div>

        {/* billing address */}
        <div className={classes.billingAddress}>
          <InvoiceDetailsSection
            header={INVOICE_DETAILS_BILLING_ADDRESS_HEADER}
            rows={[
              {
                label: INVOICE_DETAILS_BILLING_ADDRESS_NAME,
                value: `${invoiceDetails.billTo?.recipient?.firstName}${
                  invoiceDetails.billTo?.recipient?.middleName ? ' ' : ''
                }${invoiceDetails.billTo?.recipient?.middleName}${
                  invoiceDetails.billTo?.recipient?.lastName ? ' ' : ''
                }${invoiceDetails.billTo?.recipient?.lastName}`,
                testId: 'invoice-billing-address-name',
              },
              {
                label: INVOICE_DETAILS_BILLING_ADDRESS_ADDRESS1,
                value:
                  invoiceDetails.billTo?.address?.address1 +
                  (invoiceDetails.billTo?.address?.address2 &&
                    `, ${invoiceDetails.billTo?.address?.address2}`) +
                  (invoiceDetails.billTo?.address?.address3 &&
                    `, ${invoiceDetails.billTo?.address?.address3}`) +
                  (invoiceDetails.billTo?.address?.address4 &&
                    `, ${invoiceDetails.billTo?.address?.address4}`),
                testId: 'invoice-billing-address1',
              },
              {
                label: INVOICE_DETAILS_BILLING_ADDRESS_ADDRESS2,
                value:
                  invoiceDetails.billTo?.address?.city +
                  (invoiceDetails.billTo?.address?.state &&
                    `, ${invoiceDetails.billTo?.address?.state}`) +
                  (invoiceDetails.billTo?.address?.zipCode &&
                    `, ${invoiceDetails.billTo?.address?.zipCode}`),
                testId: 'invoice-billing-address2',
              },
              {
                label: INVOICE_DETAILS_BILLING_ADDRESS_DAYTIME,
                value: invoiceDetails.billTo?.contactInformation?.dayPhoneNumber,
                testId: 'invoice-billing-address-daytime',
              },
            ]}
          />
        </div>
      </div>
      <div className={`${classes.invoiceWrapperCol} ${classes.invoiceWrapperColRight}`}>
        {/* header charges and discount */}
        <div className={classes.chargesAndDiscount}>
          <InvoiceDetailsSection
            header={INVOICE_DETAILS_CHARGES_DISCOUNT_HEADER}
            rows={[
              {
                label: INVOICE_DETAILS_CHARGES_DISCOUNT_CHARGES,
                value: formatCurrency(getHeaderCharges(headerCharges), invoiceDetails.currency),
                testId: 'invoice-charges-discount-charges',
              },
              {
                label: INVOICE_DETAILS_CHARGES_DISCOUNT_DISCOUNTS,
                value: formatCurrency(getHeaderDiscounts(headerCharges), invoiceDetails.currency),
                testId: 'invoice-charges-discount-discounts',
              },
              {
                label: INVOICE_DETAILS_CHARGES_DISCOUNT_TAX,
                value: formatCurrency(getHeaderTaxes(headerTaxes), invoiceDetails.currency),
                testId: 'invoice-charges-discount-tax',
              },
            ]}
          />
        </div>
        {/* totals */}
        <div className={classes.totals}>
          <InvoiceDetailsSection
            header={INVOICE_DETAILS_TOTALS_HEADER}
            rows={[
              {
                label: INVOICE_DETAILS_TOTALS_LINE_SUBTOTALS,
                value: formatCurrency(getLineSubtotals(orderLines), invoiceDetails.currency),
                testId: 'invoice-subtotals',
              },
              {
                label: INVOICE_DETAILS_TOTALS_TOTAL_CHARGES,
                value: formatCurrency(
                  getHeaderCharges(headerCharges) + getAllLineCharges(orderLines),
                  invoiceDetails.currency
                ),
                testId: 'invoice-total-charges',
              },
              {
                label: INVOICE_DETAILS_TOTALS_TOTAL_DISCOUNTS,
                value: formatCurrency(
                  getHeaderDiscounts(headerCharges) + getAllLineDiscounts(orderLines),
                  invoiceDetails.currency
                ),
                testId: 'invoice-total-discounts',
              },
              {
                label: INVOICE_DETAILS_TOTALS_TOTAL_TAX,
                value: formatCurrency(
                  getHeaderTaxes(headerTaxes) + getAllLineTaxes(orderLines),
                  invoiceDetails.currency
                ),
                testId: 'invoice-total-tax',
              },
            ]}
          />
        </div>
        {/* transaction */}
        <div className={classes.transactions}>
          <div className={classes.totals}>
            <InvoiceDetailsSection
              header={INVOICE_DETAILS_TRANSACTIONS_HEADER}
              rows={[
                {
                  label: INVOICE_DETAILS_TRANSACTIONS_TRANSACTION_DATE,
                  value: formatDate(invoiceData?.chargeTransactionDetails?.transactionDate, locale),
                  testId: 'invoice-transaction-date',
                },
                {
                  label: INVOICE_DETAILS_TRANSACTIONS_TRANSACTION_TYPE,
                  value:
                    invoiceData?.chargeTransactionDetails?.creditCardTransactions[0]
                      ?.transactionDescription,
                  testId: 'invoice-transaction-type',
                },
                {
                  label: INVOICE_DETAILS_TRANSACTIONS_AMT,
                  value: formatCurrency(
                    invoiceData?.chargeTransactionDetails?.paymentMethod?.totalChargedAmount,
                    invoiceDetails.currency
                  ),
                  testId: 'invoice-transaction-amt',
                },
                {
                  label: INVOICE_DETAILS_TRANSACTIONS_STATUS,
                  value: invoiceData?.chargeTransactionDetails?.status,
                  testId: 'invoice-transaction-status',
                },
                {
                  label: INVOICE_DETAILS_TRANSACTIONS_REF_ID,
                  element: (
                    <div className={classes.inlineBlock}>
                      <Tooltip
                        placement='top'
                        arrow
                        title={requestId}
                        data-testid='invoice-transaction-ref-id-val'>
                        <Typography align='right' variant='body2' className={classes.boldText}>
                          {trimTo(requestId, 30)}
                        </Typography>
                      </Tooltip>
                      <CopyButton copyText={requestId} />
                    </div>
                  ),
                  testId: 'invoice-transaction-ref-id',
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={classes.itemsTable}>
        <Table>
          <TableBody>
            <BasicTableRow
              header
              rowClassName={classes.headerRow}
              data={[
                `${TABLE_HEADER_STYLE_NUMBER}:`,
                `${TABLE_HEADER_STYLE_NAME}:`,
                `${TABLE_HEADER_CLR}:`,
                `${TABLE_HEADER_SIZE}:`,
                `${TABLE_HEADER_QTY}:`,
                `${TABLE_HEADER_UNIT}:`,
                `${TABLE_HEADER_EXT_PRICE}:`,
                `${TABLE_HEADER_EXT_CHRG}:`,
                `${TABLE_HEADER_TAX}:`,
                `${TABLE_HEADER_TOTAL}:`,
              ]}
              cellClassName={`${classes.itemCellWithNoBorder} ${classes.lightText}`}
              cellRootClassName={classes.itemDetailCellRoot}
            />
            {/* order lines */}
            {invoiceDetails.orderLines.map((orderLineItem, key) => {
              return (
                <BasicTableRow
                  key={key + orderLineItem}
                  header
                  rowClassName={classes.headerRow}
                  testId={`orderLine-row-${key}`}
                  data={[
                    orderLineItem.styleNumber,
                    orderLineItem.item?.itemDescription,
                    orderLineItem.colorCode,
                    orderLineItem.displaySize,
                    orderLineItem.quantity,
                    formatCurrency(
                      orderLineItem?.linePriceInformation?.unitPrice,
                      invoiceDetails.currency
                    ),
                    formatCurrency(
                      orderLineItem?.linePriceInformation?.extendedPrice,
                      invoiceDetails.currency
                    ),
                    formatCurrency(getLineCharges(orderLineItem), invoiceDetails.currency),
                    formatCurrency(getLineTaxes(orderLineItem), invoiceDetails.currency),
                    formatCurrency(orderLineItem.lineTotal, invoiceDetails.currency),
                  ]}
                  cellClassName={`${classes.itemCellWithNoBorder} ${classes.boldText}`}
                  cellRootClassName={classes.itemDetailCellRoot}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      <hr className={classes.divider} />
      {/* resend invoice button */}
      <HasPermission permission={ResendInvoice}>
        {isShowResendInvoiceButton && <ResendEmail invoiceDetails={invoiceDetails} />}
      </HasPermission>
    </>
  ) : (
    ''
  );
}

InvoiceDetails.propTypes = {
  invoiceDetails: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  transactionKey: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  invoiceWrapperCol: {
    display: `flex`,
    flexDirection: `column`,
    gap: `12px`,
    flexBasis: `35%`,
    flexGrow: 2,
  },
  invoiceWrapperColRight: {
    marginRight: '12px',
  },
  itemsTable: {
    flexBasis: `100%`,
    padding: `${theme.spacing(1.5)}px`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    margin: '0px 12px',
  },
  headerRow: {
    height: '30px',
  },
  itemDetailCellRoot: {
    userSelect: 'text',
    cursor: 'text',
    padding: '5px 0 5px 15px',
    margin: 'none',
  },
  itemCellWithNoBorder: {
    display: 'table-cell',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
    border: 'none',
  },
  title: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    letterSpacing: '0.03em',
    fontSize: '1.25rem',
    fontWeight: 500,
  },

  chargeDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'space-between',
  },
  chargeDetailsColumn: {
    borderBottom: 0,
    padding: theme.spacing(2),
  },
  indentedCharge: {
    display: 'block',
    border: 'none',
    borderBottom: 'none',
    color: theme.palette.grey[700],
    fontSize: '0.8125rem',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(5),
    whiteSpace: 'pre',
  },
  label: {
    color: theme.palette.grey[900],
    paddingRight: theme.spacing(1),
  },
  lightText: {
    color: '#707072',
  },
  boldText: {
    fontWeight: 500,
  },
  inlineBlock: {
    display: 'inline-flex',
  },
}));
