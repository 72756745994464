/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import {
  DialogTypes,
  ResponseStatuses,
  ApiTimeOut,
  TimeOutErrorMessageFromGrand,
  RetryMaxCount,
} from '../../../../constants/dialog.const';
import { SendResendReturnLabel } from './../../../../constants/permissions.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { generateAccertifyReturnRequestPayload } from './requestPayloads/accertifyPayLoads';
import ACCERTIFY_RETURN_REQUEST_MUTATION from '../../../../mutations/accertifyReturn.mutation';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import getOrderRoutes from '../../../../routes';
import {
  areReasonsMissingFrom,
  isEmailAddressValid,
  isQuantityMissingForLineItems,
} from '../../../../utils/dialog';
import email from './../../../../utils/email';
import { removeTypeNameFromObject } from './../../../../utils/reactUtils';
import RETURN_CAPTURE_MUTATION from '../../../../mutations/returnCapture.mutation';
import RETURN_LABEL_MUTATION from '../../../../mutations/returnLabel.mutation';
import RETURN_LOCATION_MUTATION from '../../../../mutations/returnLocation.mutation';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import useBurnGiftCards from '../../../../hooks/useBurnGiftCards';
import useHasPermission from './../../../../hooks/useHasPermission';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../hooks/useSnacks';
import {
  NextButton,
  BackButton,
  SubmitReturnCaptureButton,
  ResendReturnLabelButton,
} from '../shared/buttons';
import { stepControlSharedStyles } from '../sharedStyles';
import translations from '../dialog.i18n';
import {
  generateReturnCapturePayload,
  generateReturnLocationPayload,
} from './requestPayloads/payloads.JP';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
function StepControl() {
  const classes = useStyles();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const [, setOrderDetails] = useContext(OrderContext);
  const [isReadyToSubmitReturnOrder, setIsReadyToSubmitReturnOrder] = useState(false);
  const [queryDetailsCounter, setQueryDetailsCounter] = useState(1);
  const [queryDetailsSuccessCounter, setQueryDetailsSuccessCounter] = useState(0);
  const { hasPermission } = useHasPermission();
  const { setSlowLoading, setSnack, setError, getLoadingStatus } = useSnacks();
  const [receivingNode, setReceivingNode] = useState('');
  const [standardCarrierAlphaCode, setStandardCarrierAlphaCode] = useState('');
  const [athleteInfo] = useContext(AthleteContext);
  const setRoute = useHistoryPushWithSessionId();
  const routes = getOrderRoutes();

  const {
    prevStep,
    nextStep,
    reset,
    setReturnOrderNumber,
    setShipToAddress,
    setHasTimedOut,
  } = dialogActions;

  const {
    selectedLines,
    activeStep,
    deliveryMethod,
    dialogType,
    submissionSteps,
    returnOrderNumber,
    gcShippingAddress,
    lock,
    hasTimedOut,
  } = dialogState;

  const {
    CREATE_RETURN_TIME_OUT_ERROR_MESSAGE,
    ERROR,
    RESEND_RETURN_LABEL,
    RETURN_CREATION_FAILED,
    RETURN_LOCATION_FAILED,
    RETURN_ORDER_CREATED,
    RETURN_ORDER_NOT_AVAILABLE,
    SUCCESS,
  } = useMemoTranslations(translations);

  const { findAndBurnGiftCards } = useBurnGiftCards({});

  /**
   * A util function to determine if the submit action is ready
   */
  const shouldSubmissionButtonBeDisabled = () => {
    if (dialogState.activeStep === 2 && dialogState.returnPaymentOption === 'giftcard') {
      let isGifteeEmailAvailable = true;
      if (dialogState.isGiftReturn) {
        if (!email(dialogState.gifteeEmail)) {
          isGifteeEmailAvailable = false;
        }
      }
      // Check address for required fields only if we are in gift card address form
      if (dialogState.returnPaymentOption === 'giftcard') {
        let gcShippingAddressToBeValidated = { ...gcShippingAddress };
        // there are different requirements for refund to new digital gc in Japan
        if (!gcShippingAddressToBeValidated) return false;
        const requiredFields = [
          'email',
          'firstName',
          'lastName',
          'alternateFirstName',
          'alternateLastName',
        ];
        /* 
        returns true if all the required fields in JP refund to giftcard form has values
        else returns false
        */
        return !Object.keys(gcShippingAddressToBeValidated).every((key) => {
          if (requiredFields.includes(key)) {
            if (key === 'email') return isEmailAddressValid(gcShippingAddressToBeValidated[key]);
            else return gcShippingAddressToBeValidated[key];
          }
          return true;
        });
      } else {
        // Disable next button if the email address is not provided for gift return orders
        return !isGifteeEmailAvailable;
      }
    } else {
      return false;
    }
  };

  /**
   * Determines whether the next button, which are displayed on non-submission pages,
   * should be disabled or not based on validations.
   */
  const shouldNextButtonBeDisabled = () => {
    switch (activeStep) {
      case 0:
        return selectedItemKeys.length === 0;
      case 1:
        return (
          areReasonsMissingFrom(selectedLines, DialogTypes.RETURN) ||
          isQuantityMissingForLineItems(selectedLines, DialogTypes.RETURN)
        );
      // TODO eslint-disable-next-line no-fallthrough
      default:
        return true;
    }
  };

  /**
   * Updates url to match new return order if return order has been successfully made.
   */
  const updateUrlForNewReturn = () => {
    setRoute(`/order/${returnOrderNumber}/${routes[0]}`);
  };

  const [sendReturnLabelNow] = useMutation(RETURN_LABEL_MUTATION, {
    onError: (err) => {
      setError(`${RESEND_RETURN_LABEL} ${ERROR} ${err.message}`);
    },
    onCompleted: () => {
      // todo - do something more appropriate when the call isn't timing out?
      dispatchReset();
      setSnack(`${SUCCESS}`);
    },
  });

  /**
   * Creates a query used to retrieve the OrderDetails that can be executed at a
   * different time. This is used for the retry calls in the event that the return
   * order has been created, but is not yet retrievable.
   */
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: queryOrderDetailsError, stopPolling },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 1500,
    onError: () => {
      const isOrderDetail404 =
        queryOrderDetailsError?.message?.includes('404') &&
        /* 
          if the 404 were coming from a nested/stitched query, "path" would include nested 
          data nodes (e.g. ["orderDetail", "shipmentsV2"]), which is why we ensure the path
          only includes "orderDetail"
        */
        queryOrderDetailsError?.path?.toString() === 'orderDetail';
      /*
        If the error we receive is _not_ a 404 and we have received partial data
        then we have received order detail data for the newly created return order,
        and we should stop polling, update order details in state, and direct the user
        to the order details page for the new return order.
      */
      if (!isOrderDetail404 && queryOrderDetailsData?.orderDetail) {
        stopPolling();
        setOrderDetails(queryOrderDetailsData?.orderDetail);
        dispatchReset();
        setSnack(RETURN_ORDER_CREATED);
        updateUrlForNewReturn();
      }
      /*
          If the error we receive _is_ a 404 and/or we do not have partial data,
          then we allow polling to continue, but we tally our polls so that we may 
          cut our losses once 10 requests have been made and failed.
        */
      if (queryDetailsCounter === 10) {
        setError(RETURN_ORDER_NOT_AVAILABLE);
        dispatchReset();
        stopPolling();
      } else {
        setQueryDetailsCounter(queryDetailsCounter + 1);
      }
    },
    onCompleted: () => {
      const setReturnOrderSuccess = () => {
        // Set Order details on successful return creation
        setOrderDetails(queryOrderDetailsData.orderDetail);
        dispatchReset();
        setSnack(RETURN_ORDER_CREATED);
        updateUrlForNewReturn();
      };
      /* If call is successful without errors and orderheaderkey 
      is available, we stop making retry calls */
      if (queryOrderDetailsData?.orderDetail?.orderHeaderKey) {
        stopPolling();
        setReturnOrderSuccess();
        return;
      }
      if (queryDetailsSuccessCounter >= RetryMaxCount) {
        stopPolling();
        setReturnOrderSuccess();
      } else {
        setQueryDetailsSuccessCounter(queryDetailsSuccessCounter + 1);
      }
    },
  });

  // Generate the function to execute the return location mutation
  const [submitReturnLocation] = useMutation(RETURN_LOCATION_MUTATION, {
    onError: (err) => {
      setError(RETURN_LOCATION_FAILED);
    },
    onCompleted: (response) => {
      setReceivingNode(response.returnLocation.receivingNode);
      setStandardCarrierAlphaCode(response.returnLocation.standardCarrierAlphaCode);

      // Update the shipTo field for any order except those from NIKEEUROPE.
      const { address, recipient } = response.returnLocation.shipTo;
      const shipTo = {
        address: {
          ...removeTypeNameFromObject(address),
        },
        company: recipient.company,
      };
      dialogDispatch(setShipToAddress(shipTo));
      setIsReadyToSubmitReturnOrder(true);
    },
  });

  const [submitAccertifyRequest] = useMutation(ACCERTIFY_RETURN_REQUEST_MUTATION, {
    onError: (err) => {
      console.error('Accertify Return Request Error', err);
    },
    onCompleted: (response) => {
      console.log('Accertify Return Request Response', response);
    },
  });

  // Generate the function to execute the return captures mutation.
  const [submitReturnOrder, { loading: returnLoading }] = useMutation(RETURN_CAPTURE_MUTATION, {
    onError: (err) => {
      // Logging the error message so we do not lose track of it.
      console.error('Return Capture Error', err);
      if (err.message === TimeOutErrorMessageFromGrand) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else {
        // If the return order submission fails, we want to display a readable error and go back to the order screen.
        setError(RETURN_CREATION_FAILED);
      }
      dispatchReset();
    },
    onCompleted: async (response) => {
      const { createReturn } = response;
      if ((createReturn.status = ResponseStatuses.COMPLETED && createReturn.response)) {
        if (createReturn.response.returnOrderNumber) {
          dialogDispatch(setReturnOrderNumber(createReturn.response.returnOrderNumber));

          const burnFailures = await findAndBurnGiftCards();

          // if burning GCs failed, reset dialog do not query order details
          if (Object.keys(burnFailures).length > 0) {
            dispatchReset();
            // otherwise query order details + close dialog
          } else {
            queryOrderDetails({
              variables: {
                orderNumber: createReturn.response.returnOrderNumber,
                isFollowUp: true,
              },
            });
          }
        }
      } else if (
        createReturn.status === ResponseStatuses.IN_PROGRESS ||
        createReturn.status === ResponseStatuses.PENDING
      ) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (createReturn.error) {
        const errorMessageOnJobCompletion = `${createReturn.error.httpStatus}: ${createReturn.error.message}`;
        setError(errorMessageOnJobCompletion);
        dispatchReset();
      }
    },
  });

  // Need to wait for shipTo address to be populated in state before we can request a return
  useEffect(() => {
    if (isReadyToSubmitReturnOrder) {
      // Loop through the selected lines for return and make accertify call for each line separately
      Object.keys(dialogState.selectedLines).forEach((key) => {
        const selectedLine = dialogState.selectedLines[key];
        const accertifyRequestPayLoad = generateAccertifyReturnRequestPayload(
          dialogState,
          selectedLine,
          orderDetail,
          athleteInfo
        );
        // For phase 1 as we are not going to take any action on the accertify response we
        // just make parallel calls and leave it.
        submitAccertifyRequest({ variables: { input: accertifyRequestPayLoad } });
      });
      const returnCapturesInput = generateReturnCapturePayload(
        dialogState,
        consumerState,
        orderDetail,
        hasPermission(SendResendReturnLabel),
        receivingNode,
        standardCarrierAlphaCode,
        athleteInfo
      );
      submitReturnOrder({
        variables: {
          input: returnCapturesInput,
          region: orderDetail.omsRegionReference,
          timeout: ApiTimeOut,
        },
      });
    }
  }, [isReadyToSubmitReturnOrder]);

  const dispatchReset = () => {
    !lock && dialogDispatch(reset());
  };

  // Determines if we are on a submission step
  const determineIfSubmissionStep = () => {
    if (submissionSteps.includes(activeStep)) {
      if (dialogType === DialogTypes.RESEND_RETURN_LABEL) {
        return true;
      } else if (activeStep === 2) {
        return true;
      }
    }
    return false;
  };

  const isSubmissionStep = determineIfSubmissionStep();
  const selectedItemKeys = Object.keys(selectedLines);
  const isSubmissionLoading = getLoadingStatus() || returnLoading;

  /**
   * Handles the submission of the createReturn mutation.
   */
  function handleReturnOrderSubmit() {
    const returnLocationInput = generateReturnLocationPayload(
      dialogState,
      consumerState,
      orderDetail
    );

    setSlowLoading();

    submitReturnLocation({ variables: { input: returnLocationInput } });

    /*
     * We need to wait for state to update - see useEffect for the submitReturnOrder call
     * which watches for dialogueState.shipTo address to update
     */
  }

  const handleNext = () => {
    dialogDispatch(nextStep());
  };

  const handleResendReturnLabelClick = () => {
    setSlowLoading();
    sendReturnLabelNow({
      variables: {
        input: {
          returnOrderNumber: orderDetail.orderNumber,
          dispatchType: String(deliveryMethod).toUpperCase(),
        },
      },
    });
  };

  // action buttons, depending on dialog type
  const getSubmissionButton = () => {
    switch (dialogType) {
      case DialogTypes.RETURN: {
        return (
          <SubmitReturnCaptureButton
            disabled={shouldSubmissionButtonBeDisabled() || isSubmissionLoading || hasTimedOut}
            onClick={handleReturnOrderSubmit}
          />
        );
      }
      case DialogTypes.RESEND_RETURN_LABEL: {
        return (
          <ResendReturnLabelButton
            disabled={shouldSubmissionButtonBeDisabled()}
            onClick={handleResendReturnLabelClick}
          />
        );
      }
      default:
        return;
    }
  };

  if (!isSubmissionStep) {
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <NextButton disabled={shouldNextButtonBeDisabled()} onClick={handleNext} />
      </div>
    );
  } else {
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        {getSubmissionButton()}
      </div>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));

export default withRouter(StepControl);
