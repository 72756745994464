/** React / Utils */
import React, { useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';

/** Material UI */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/index';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/** Local */
import translations from './emailBody.i18n';
import Loading from '../../../shared/loading';
import HasPermission from '../../../shared/hasPermission';
import useSnacks from '../../../../hooks/useSnacks';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import buildAttachmentUrl from '../../../../utils/buildAttachmentUrl';
import OMA_SEARCH_DETAIL_QUERY from '../../../../queries/notificationDetailsOMA.query';
import NOTIFICATION_RESEND_OMA_MUTATION from '../../../../mutations/notificationResendOMA.mutation';
import { Granted, ResendEmail } from '../../../../constants/permissions.const';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { PermissionContext } from '../../../../store/contexts/permissionContext';
import ResponseSnackBar from '../../../shared/snackbar';
import useMemoTranslations from '../../../../hooks/useMemoTranslations.js';
import { getBillingEmail } from '../../../../utils/order';

/**
 * Page for viewing the contents of a communication, including any attachments
 */
const EmailBody = ({ match }) => {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [, hasModificationPermissions] = useContext(PermissionContext);
  const { setSlowLoading, setSnack, setError } = useSnacks();
  const setRoute = useHistoryPushWithSessionId();
  const [comsDetails, setComsDetails] = useState(null);
  const [comsAddr, setComsAddr] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const isKorea = orderDetail.omsRegionReference === 'NIKEKR';
  const history = useHistory();
  const orderDetailLocale = orderDetail.locale ? orderDetail.locale : 'na/na';
  const langCountry = orderDetailLocale.includes('_') ? orderDetailLocale.split('_') : '';
  const canModifyInput = hasModificationPermissions === Granted;

  const {
    ATTACHMENTS,
    EMAIL_DETAILS_ERROR,
    EXIT,
    NO_COMM_DETAILS_AVAILABLE,
    RESEND_EMAIL,
    RESEND_COMM_ERROR,
    RESEND_COMM_SUCCESS,
    RESEND_TO,
    COMMUNICATION_DETAIL,
    RESEND_SMS,
  } = useMemoTranslations(translations);

  // get communication id from url params
  const sendId = match?.params?.requestId;
  const comBody = comsDetails?.communication_body;

  const hasAttachments = !Boolean(comsDetails?.has_attachments === 'N');
  const isResend = !Boolean(comsDetails?.is_resend === 'N');

  /**
   * This function extracts salesforce template variable extra characters such as [SET], %%, etc
   
   * @param {string} comBody - comsDetails?.communication_body from api response
  * @returns {string} emailRender - Parsed comBody to render to page.
   */
  const returnRender = (comBody) => {
    let emailRender = '';
    for (let i = 0; i < comBody.length; i++) {
      if (comBody[i] === '%' && comBody[i + 1] === '%') {
        while (comBody[i - 1] !== '@' && i < comBody.length) {
          i++;
        }
        while (comBody[i] !== ' ' && comBody[i] !== ')') {
          emailRender += comBody[i];
          i++;
        }
        while (comBody[i - 2] !== '%' && comBody[i - 1] !== '%') {
          i++;
        }
      } else {
        emailRender += comBody[i];
      }
    }
    emailRender = emailRender.replace(feedBackSurvey, ' N/A');
    return emailRender.replace(emailOrderStatusLink, nikeOrderStatusLink);
  };

  /**
   * Uses feedBackSurvey string in a replace method to extract all excess feedback survey info
   * Without this replace, the unnecessary text will stretch the page
   */
  const feedBackSurvey =
    '_var_3<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_3 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_4<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_4 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_5<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_5 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_6<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_6 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_7<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_7 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>';
  // without this replace the Order Status button links to a blank screen.
  const emailOrderStatusLink = 'httpgetwrap|ORDERSTATUS_var_0';
  const nikeOrderStatusLink = langCountry
    ? `https://www.nike.com/${langCountry[1]}/${langCountry[0]}/orders/sales/${orderDetail?.orderNumber}`
    : `https://www.nike.com/orders/sales/${orderDetail?.orderNumber}`;

  const { data, loading, error } = useQuery(OMA_SEARCH_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    variables: { sendId: sendId, channel: isKorea ? 'sms' : 'email' },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      if (!error?.message?.includes('404')) {
        console.error('Notification details:', error);
        setError(`${EMAIL_DETAILS_ERROR}: ${error.message}`);
      } else {
        setComsDetails([]);
        setError(`${NO_COMM_DETAILS_AVAILABLE}: ${error.message}`);
      }
    },
    onCompleted: () => {
      const comsDetails = data?.searchDetailOMA;

      setComsDetails(comsDetails);
      if (isKorea) {
        setComsAddr(comsDetails?.user_phone_number);
      } else {
        setComsAddr(getBillingEmail(orderDetail));
      }
    },
  });

  const backToComs = () => {
    const orderId = comsDetails?.order_id;
    const isStandalone = match?.params?.standalone === 'standalone';
    // check if email data does not exist return
    if (!orderId) {
      history.goBack();
      return;
    }
    // check if we got here from standalone component using url param and go to the correct route
    if (isStandalone) {
      setRoute(`/embedded/emails/${orderId}`);
    } else {
      setRoute(`/order/${orderId}/emails`);
    }
  };

  const validateEmail = (email) => {
    /* 
    regex here will check for a valid email:
    - valid email string before @ 
    - valid string for domain name
    - has '.' present at least once
    - prevents multiple @ signs
    - checks for at least 2 letters after the last '.'
    */

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    /**
     * regex validates that the phone number is in a valid format
     * https://developer.niketech.com/docs/projects/Omni%20Archive%20Resend?tab=api
     * provided by the service team bless them
     */
    const re = /^\+\d{1,3}\s?\d{1,14}(\s\d{1,13})?/;
    return re.test(String(phone));
  };

  // handle communication field input disable/enable button when validating input
  const comInput = (e) => {
    setComsAddr(e.target.value);

    // validate email here
    if (!isKorea && !validateEmail(e.target.value)) {
      setResendDisabled(true);
    } else if (isKorea && !validatePhone(e.target.value)) {
      setResendDisabled(true);
    } else {
      setResendDisabled(false);
    }
  };

  // handles request submission when resend email button is clicked
  const handleSubmit = () => {
    setSlowLoading();
    backToComs();
    const requestId = comsDetails?.send_id;
    const requestBody = {
      original_communication_send_date: comsDetails?.creation_date,
      channel: comsDetails?.channel,
      locale_language: comsDetails?.locale_language,
      marketplace: comsDetails?.marketplace,
    };

    if (isKorea) {
      requestBody.user_phone_number = comsAddr;
    } else {
      requestBody.user_email_address = comsAddr;
    }

    resendEmail({
      variables: { requestBody, requestId },
    });
  };

  // Mutation that calls email resend service
  const [resendEmail] = useMutation(NOTIFICATION_RESEND_OMA_MUTATION, {
    onError: (err) => {
      setError(`${RESEND_COMM_ERROR} ${err.message}`);
    },
    onCompleted: (response) => {
      const { errors } = response;
      if (errors) {
        // show error message
        setSnack(`${RESEND_COMM_ERROR}  ${errors[0]?.message}`);
      } else {
        // show success message
        setSnack(`${RESEND_COMM_SUCCESS}`);
      }
    },
  });

  return (
    <>
      <Typography variant='h1' className='accessibly-hidden'>
        {COMMUNICATION_DETAIL}
      </Typography>
      {loading && <Loading />}

      {/* resend com section */}
      {!loading && (
        <>
          <section className={classes.comsHeader} role='banner'>
            {!isResend && (
              <HasPermission permission={ResendEmail}>
                <div>
                  <Typography className={classes.resendTitle} data-testid={'resend-title'}>
                    {RESEND_TO}
                  </Typography>
                  <TextField
                    className={classes.resendField}
                    data-testid={'resend-com-input'}
                    id='com-addr-input'
                    variant='outlined'
                    InputProps={{
                      classes: {
                        notchedOutline: classes.comInputOutline,
                        input: classes.comInput,
                      },
                    }}
                    value={comsAddr}
                    margin='dense'
                    style={{ width: 300 }}
                    onChange={comInput}
                    disabled={!canModifyInput}
                  />
                  <Button
                    className={classes.resendButton}
                    data-testid={'resend-com-button'}
                    disabled={resendDisabled}
                    role='button'
                    onClick={handleSubmit}>
                    {isKorea ? RESEND_SMS : RESEND_EMAIL}
                  </Button>
                </div>
              </HasPermission>
            )}
            {!isResend && (
              <Button
                className={classes.exitButton}
                onClick={backToComs}
                data-testid={'exit-button'}
                role='button'>
                {EXIT}
              </Button>
            )}
          </section>
        </>
      )}

      {/* set line break if both resend com and attachments exist */}
      {!loading && !isResend && hasAttachments && (
        <section className={classes.lineBreak} role='banner'>
          <hr></hr>
        </section>
      )}

      {/* set exit button on its own if not resend com or attachments */}
      {!loading && isResend && !hasAttachments && (
        <section className={classes.comsHeader} role='banner'>
          <Button
            className={classes.exitButton}
            onClick={backToComs}
            data-testid={'exit-button'}
            role='button'>
            {EXIT}
          </Button>
        </section>
      )}

      {/* attachment section */}
      {!loading && (
        <section className={classes.comsHeader} role='banner'>
          {hasAttachments && (
            <div>
              <Typography className={classes.attachmentTitle} data-testid={'attachment-title'}>
                {ATTACHMENTS}
              </Typography>
              <div>
                {hasAttachments &&
                  comsDetails?.attachments?.map((attachment, i) => (
                    <Link
                      key={i}
                      id={`attachment-{i}`}
                      variant='body1'
                      className={classes.attachments}
                      download={attachment.name}
                      href={buildAttachmentUrl(attachment, i)}>
                      {attachment.name}
                    </Link>
                  ))}
              </div>
            </div>
          )}
          {isResend && hasAttachments && (
            <Button
              className={classes.exitButton}
              onClick={backToComs}
              data-testid={'exit-button'}
              role='button'>
              {EXIT}
            </Button>
          )}
        </section>
      )}

      {comBody && !isKorea && (
        <div
          dangerouslySetInnerHTML={{
            __html: returnRender(comBody),
          }}
          role='main'
          className={classes.comBody}
          data-testid={'coms-body'}></div>
      )}

      {comBody && isKorea && (
        <div role='main' className={classes.comBody} data-testid={'coms-body'}>
          <div className={classes.smsBody} data-testid={'sms-body'}>
            {comBody}
          </div>
        </div>
      )}
      <ResponseSnackBar />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  attachmentTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1em',
  },
  attachments: {
    'display': 'inline-block',
    'border': `1px solid ${theme.palette.common.white}`,
    'color': theme.palette.common.white,
    'padding': `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    'margin': theme.spacing(1),
    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  comsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  comBody: {
    height: '100%',
    padding: '20px 200px',
    background: theme.palette.grey[200],
  },
  smsBody: {
    padding: '40px 20px',
    width: 'auto',
    maxWidth: '400px',
    margin: 'auto',
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    borderRadius: '10px',
  },
  exitButton: {
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },
  lineBreak: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  resendButton: {
    color: theme.palette.common.white,
    marginLeft: '10px',
    marginTop: '10px',
    border: '1px solid white',
  },
  resendField: {
    marginLeft: theme.spacing(1),
  },
  comInputOutline: {
    borderWidth: '1px',
    borderColor: 'white !important',
  },
  comInput: {
    'color': 'black',
    'backgroundColor': 'white',
    'borderRadius': 'inherit',
    '&:disabled': {
      color: '#9e9e9e',
    },
  },
  resendTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1em',
  },
}));

export default EmailBody;
