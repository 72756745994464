/** React/Utils */
import React from 'react';
import PropTypes from 'prop-types';

/** Material UI */
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FaceIcon from '@material-ui/icons/Face';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Mail from '@material-ui/icons/Mail';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import BasicTableRow from '../../../../shared/table/tableRow';
import {
  paymentServiceMethodLabels,
  PaymentServiceMethodsDict,
} from '../../../../../constants/paymentTypes.const';
import {
  prepareAddressObject,
  convertBillingAddressToBillingInfo,
} from '../../../../../utils/address';
import { getLast4 } from '../../../../../utils/payment';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import PaymentMethodStatus from './paymentMethodStatus';
import translations from '../paymentDetails.i18n';
import PaymentMods from './paymentMethodMods';

/**
 * TODO: this is a duplicate of original payment details component. the other component still lives
 * and is being used until we completely use payment details service for this data. The other one
 * can be removed or all this logic can be moved to original (paymentDetails.js)
 * once migration is complete.
 *
 * Payment Types details component.
 * Provide it:
 * the payment - each payment comes with its own transactions (operationRequests),
 * credit card/gift card info (creditCardInfo/giftCardInfo),
 * and summary payment information (orderPayment)
 * children - table of the transactions for the payment
 */
export default function PaymentDetails2({
  cardClass,
  cellClass,
  children,
  index,
  orderNumber,
  orderType,
  parentReturnOrder,
  partner,
  payment,
  region,
  tableHeadingClass,
  updatePaymentDetails,
  activeCC,
  billingAddress,
}) {
  const classes = useStyles();

  const {
    ARIA_PAYMENT_DETAILS_1,
    ARIA_PAYMENT_SUMMARY,
    AUTHORIZED,
    CHARGED,
    DETAILS,
    PAYMENT_TYPE,
    REFUNDED,
    REQUESTED_AUTH,
    REQUESTED_CHARGE,
    MERCHANT_ID,
  } = useMemoTranslations(translations);

  const { CREDIT_CARD, GIFT_CARD, BANK_TRANSFER, COD } = PaymentServiceMethodsDict;

  const {
    operationRequests,
    orderPayment,
    creditCardInfo,
    giftCardInfo,
    bankTransferInfo,
  } = payment;
  const { paymentMethod, paymentStatus, paymentId, billingInfo } = orderPayment;
  const isPaymentMethodActive = paymentStatus === 'ACTIVE';
  // vouchers have paymentMethod GiftCard, so we use this other field to check for vouchers
  const isVoucher = orderPayment?.account === 'voucher';
  const isBankPayment = paymentMethod === BANK_TRANSFER;
  const isGiftCard = paymentMethod === GIFT_CARD;

  /*
    Giftcards do not have billing information attached to them and so we will
    use billingAddress as a substitute. It contains the same information, just
    extracted out, hence the need for 'convertBillingAddressToBillingInfo'
   */
  const billingObject = isGiftCard
    ? convertBillingAddressToBillingInfo(billingAddress)
    : billingInfo;
  const address = prepareAddressObject(billingObject);
  const dayPhoneNum = billingObject?.phoneNumber;
  const displayName = billingObject?.firstName || billingObject?.lastName ? address?.shift() : null;
  const formattedAddress = address?.join('\n');
  const emailAddress = billingObject?.email;

  // get total auth requested from all AUTH operation requests, remove leading 0s with +
  const totalRequestedAuth =
    +operationRequests
      ?.filter((op) => op.action === 'AUTH')
      ?.reduce((acc, op) => (acc += op.requestAmount), 0) || null;
  // get total charge requested from all DEBIT operation requests, remove leading 0s with +
  const totalRequestedCharge =
    +operationRequests
      ?.filter((op) => op.action === 'DEBIT')
      ?.reduce((acc, op) => (acc += op.actualAmount), 0) || null;

  const summaryHeaders = [REQUESTED_AUTH, AUTHORIZED, REQUESTED_CHARGE, CHARGED, REFUNDED];
  const summaryValues = [
    totalRequestedAuth,
    orderPayment?.amountAuthorized,
    totalRequestedCharge,
    orderPayment?.amountDebited,
    orderPayment?.amountCredited,
  ];

  /**
   * This function checks the payment method type and returns the correct card header
   * with the applicable information. Extracted because this was cleaner than a bunch of nested
   * ternaries and can be modified to handle other payment methods in the future if necessary.
   * @returns typography react component with payment method information
   */
  const getPaymentMethodTitle = () => {
    if (isVoucher) {
      return (
        <Typography variant='h5' component='h2' className={classes.title}>
          {`${PAYMENT_TYPE}:
          ${orderPayment?.account[0]?.toUpperCase() + orderPayment?.account?.slice(1)}`}
        </Typography>
      );
    }

    const paymentMethodLabel = paymentServiceMethodLabels[paymentMethod] || paymentMethod;
    switch (paymentMethod) {
      case CREDIT_CARD: {
        const cardType = creditCardInfo?.cardType || '';
        return (
          <Typography variant='h5' component='h2' className={classes.title}>
            {`${PAYMENT_TYPE}: ${cardType} ${paymentMethodLabel}`}
            <span
              className={classes.merchAccountDisplay}
              data-testid={`cc-merchant-acccount-${orderPayment?.account}`}>
              {orderPayment?.account ? `${MERCHANT_ID}:${orderPayment?.account}` : ''}
            </span>
          </Typography>
        );
      }
      case GIFT_CARD: {
        return (
          <Typography variant='h5' component='h2' className={classes.title}>
            {`${PAYMENT_TYPE}: ${paymentMethodLabel}`}
          </Typography>
        );
      }
      case BANK_TRANSFER:
      default:
        return (
          <Typography variant='h5' component='h2' className={classes.title}>
            {`${PAYMENT_TYPE}: ${paymentMethodLabel}`}
            <span
              className={classes.merchAccountDisplay}
              data-testid={`bank-merchant-acccount-${orderPayment?.account}`}>
              {orderPayment?.account ? `${MERCHANT_ID}: ${orderPayment?.account}` : ''}
            </span>
          </Typography>
        );
    }
  };

  return (
    <>
      <Box className={classes.titleContainer}>
        {/* payment method type and card info */}
        {getPaymentMethodTitle()}

        {/* edit payment method button */}
        {/* 
        TODO uncomment when editing a payment has been implemented
        <HasPermission permission={EditPaymentInfo}>
          <IconButton
            variant='outlined'
            color='default'
            label={EDIT_PAYMENT.toLowerCase()}
            aria-label={EDIT_PAYMENT.toLowerCase()}
            className={classes.editIcon}
            disabled={!editPaymentCspUrl}
            data-testid='edit-payment-button'>
            <EditIcon />
          </IconButton>
        </HasPermission> */}
      </Box>

      {/* payment method details */}
      <Paper className={cardClass} data-testid={`payment-details-${paymentId}`}>
        <>
          {paymentMethod !== COD && (
            <List aria-label={ARIA_PAYMENT_DETAILS_1} className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ root: classes.listItemText, primary: classes.listItemTextPrimary }}
                  primary={isBankPayment ? bankTransferInfo.accountName : displayName}
                  data-testid={`pmt-display-name-${index}`}
                />
              </ListItem>
              {isBankPayment && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: classes.listItemText,
                      primary: classes.listItemTextPrimary,
                      secondary: classes.listItemTextPrimary,
                    }}
                    primary={bankTransferInfo.bankName}
                    secondary={bankTransferInfo.swiftCode}
                    data-testid={`bank-name-swift-code-${index}`}
                  />
                </ListItem>
              )}
              {isBankPayment && bankTransferInfo.bankCity && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: classes.listItemText,
                      primary: classes.listItemTextPrimary,
                    }}
                    primary={bankTransferInfo.bankCity}
                    data-testid={`bank-city-${index}`}
                  />
                </ListItem>
              )}
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <HomeWorkIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ root: classes.listItemText, primary: classes.listItemTextPrimary }}
                  primary={formattedAddress}
                  primaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}
                  data-testid={`pmt-address-${index}`}
                />
              </ListItem>
              {!isBankPayment && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ root: classes.listItemText, primary: classes.listItemTextPrimary }}
                    primary={dayPhoneNum}
                    data-testid={`pmt-phone-${index}`}
                  />
                </ListItem>
              )}
              {isGiftCard && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ root: classes.listItemText, primary: classes.listItemTextPrimary }}
                    primary={emailAddress}
                    data-testid={`pmt-email-${index}`}
                  />
                </ListItem>
              )}
            </List>
          )}
          <Divider />
          {/* payment method status section */}
          <Box className={classes.container}>
            <PaymentMethodStatus
              creditCardInfo={creditCardInfo}
              giftCardInfo={giftCardInfo}
              bankTransferIBAN={bankTransferInfo?.iban}
              paymentType={isVoucher ? orderPayment?.account : orderPayment?.paymentMethod}
              paymentStatus={paymentStatus}
              orderNumber={orderNumber}
              currency={orderPayment?.currency}
            />
            <PaymentMods
              last4Digits={getLast4(payment)}
              activeCC={activeCC}
              cardType={creditCardInfo?.cardType}
              index={index}
              isPaymentMethodActive={isPaymentMethodActive}
              orderNumber={orderNumber}
              orderType={orderType}
              parentReturnOrder={parentReturnOrder}
              partner={partner}
              paymentId={paymentId}
              paymentMethod={paymentMethod}
              region={region}
              updatePaymentDetails={updatePaymentDetails}
            />
          </Box>
        </>
        <Divider />
        {/* transactions summary section */}
        <Typography
          aria-label={DETAILS.toLowerCase()}
          variant='subtitle1'
          component='h3'
          className={classes.cardSubHeading}>
          {DETAILS}
        </Typography>
        <Table aria-label={ARIA_PAYMENT_SUMMARY}>
          <TableBody>
            <BasicTableRow header data={summaryHeaders} cellClassName={tableHeadingClass} />
            <BasicTableRow data={summaryValues} cellClassName={cellClass} />
          </TableBody>
        </Table>
        {/* transactions section */}
        {children}
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    padding: 0,
    margin: 0,
  },
  accordionExpandIcon: {
    padding: theme.spacing(1.2),
    paddingRight: 0,
    marginRight: theme.spacing(1.2),
  },
  accordionRoot: {
    '&.Mui-expanded': {
      marginTop: 0,
    },
    '&::before': {
      backgroundColor: 'unset',
    },
  },
  accordionSummary: {
    padding: 0,
    minHeight: 'unset',
  },
  accordionSummaryExpanded: {
    'padding': 0,
    'margin': 0,
    '& > .MuiAccordionSummary-content': {
      margin: 0,
    },
    '&.Mui-expanded': {
      'min-height': 'unset',
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  cardSubHeading: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: theme.spacing(1.25),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2.5)}px`,
  },
  list: {
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.25),
  },
  listItem: {
    minHeight: '1em',
    maxWidth: 'max-content',
    alignItems: 'flex-start',
    marginRight: theme.spacing(3.75),
    padding: 0,
  },
  listItemIcon: {
    minWidth: 'unset',
    marginRight: theme.spacing(1.25),
    color: theme.palette.text.primary,
  },
  listItemText: {
    color: theme.palette.text.primary,
    margin: 0,
  },
  listItemTextPrimary: {
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    paddingBottom: '0.1rem',
  },
  title: {
    margin: 0,
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.2,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  merchAccountDisplay: {
    float: 'right',
  },
}));

PaymentDetails2.propTypes = {
  index: PropTypes.number.isRequired,
  cardClass: PropTypes.string.isRequired,
  tableHeadingClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  payment: PropTypes.shape({
    operationRequests: PropTypes.arrayOf(
      PropTypes.shape({
        paymentId: PropTypes.string,
        action: PropTypes.string,
        paymentMethod: PropTypes.string,
        requestAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        currency: PropTypes.string,
        actualAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        requestStatus: PropTypes.string,
        createTime: PropTypes.string,
        updateTime: PropTypes.string,
      })
    ),
    orderPayment: PropTypes.shape({
      paymentId: PropTypes.string,
      paymentMethod: PropTypes.string,
      paymentStatus: PropTypes.string,
      currency: PropTypes.string,
      amountAuthorized: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amountVoided: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amountDebited: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      amountCredited: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      createTime: PropTypes.string,
      updateTime: PropTypes.string,
      account: PropTypes.string,
      billingInfo: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        address3: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string,
        phoneNumber: PropTypes.string,
      }),
    }),
    creditCardInfo: PropTypes.shape({
      accountNumber: PropTypes.string,
      cardType: PropTypes.string,
      expirationMonth: PropTypes.string,
      expirationYear: PropTypes.string,
    }),
    giftCardInfo: PropTypes.shape({
      accountNumber: PropTypes.string,
    }),
    bankTransferInfo: PropTypes.shape({
      accountName: PropTypes.string,
      iban: PropTypes.string,
      swiftCode: PropTypes.string,
      bankName: PropTypes.string,
      bankCity: PropTypes.string,
    }),
  }).isRequired,
  billingAddress: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      altFirstName: PropTypes.string,
      lastName: PropTypes.string,
      altLastName: PropTypes.string,
      middleName: PropTypes.string,
    }),
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      address3: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
      country: PropTypes.string,
    }),
    contactInfo: PropTypes.shape({
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  orderNumber: PropTypes.string,
  orderType: PropTypes.string,
  parentReturnOrder: PropTypes.string,
  partner: PropTypes.string,
  region: PropTypes.string,
  updatePaymentDetails: PropTypes.func.isRequired,
  activeCC: PropTypes.shape({
    accountNumber: PropTypes.string,
    paymentId: PropTypes.string,
  }),
};

PaymentDetails2.defaultProps = {
  payment: {
    operationRequests: [
      {
        paymentId: '',
        action: '',
        paymentMethod: '',
        requestAmount: '',
        currency: '',
        actualAmount: null,
        requestStatus: '',
        createTime: '',
        updateTime: '',
      },
    ],
    orderPayment: {
      paymentId: '',
      paymentMethod: '',
      paymentStatus: '',
      currency: '',
      amountAuthorized: null,
      amountVoided: null,
      amountDebited: null,
      amountCredited: null,
      createTime: '',
      updateTime: '',
    },
    creditCardInfo: {
      accountNumber: '',
      cardType: '',
      expirationMonth: '',
      expirationYear: '',
    },
    giftCardInfo: {
      accountNumber: '',
    },
    bankTransferInfo: {
      accountName: '',
      iban: '',
      swiftCode: '',
      bankName: '',
      bankCity: '',
      billingInfo: {
        email: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        phoneNumber: '',
      },
    },
    billingAddress: {
      name: {
        firstName: '',
        lastName: '',
      },
      address: {
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      contactInfo: {
        phoneNumber: '',
        email: '',
      },
    },
  },
  orderNumber: '',
  orderType: '',
  region: '',
  activeCC: {
    accountNumber: '',
    paymentId: '',
  },
};
